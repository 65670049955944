@charset "utf-8";
/* CSS Document */

@import url(https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@400;500;700&display=swap);
 
$breakpoint-pc: 1200px;
$breakpoint-tb: 960px;
$breakpoint-sp: 560px;
$main-color: #3d5f5c;

@mixin max-screen($breakpoint) { 
	@media screen and (max-width: $breakpoint) {
		@content;
	}
}

.clearfix {
	&::after {
		clear: both;
		content: "";
		display: block;
	}
}
.ellipsis {
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}
.cmn-sec {
	margin-left: auto;
	margin-right: auto;
	max-width: 1100px;
	padding-left: 50px;
	padding-right: 50px;
	@include max-screen($breakpoint-tb) {
		padding-left: 4vw;
		padding-right: 4vw;
	}
}
.pseudo-table {
	display: table;
	height: 100%;
	width: 100%;
	> div {
		display: table-cell;
		height: 100%;
		text-align: center;
		vertical-align: middle;
		width: 100%;
	}
}


/* General
--------------------- */

/* reset */
html{height:100%;overflow-y:scroll;width:100%}body{height:100%;margin:0;overflow:visible;padding:0;vertical-align:top;width:100%}h1,h2,h3,h4,h5,h6{font-size:100%;font-weight:normal;margin:0}p{margin:0}a img,img{border:0;vertical-align:middle}strong{font-style:normal;font-weight:bold}small{font-size:100%}ol,ul{list-style-type:none}dd,dl,dt,li,ol,ul{margin:0;padding:0}hr{background-color:#CCCCCC;border:0;color:#CCCCCC;height:1px;margin:0;padding:0}table{border-collapse:collapse;border-spacing:0;font-size:100%}th{font-style:normal;font-weight:normal;margin:0;padding:0;text-align:left}td{margin:0;padding:0}blockquote,div,span{margin:0;padding:0}pre{font-family:monospace;line-height:1.0;margin:0;padding:0}button{background:0;border:0;cursor:pointer;padding:0;vertical-align:middle}button,fieldset,form,input,label,legend,select,textarea{font-family:inherit;font-size:100%;font-style:inherit;font-weight:inherit;margin:0;padding:0;vertical-align:middle}label{cursor:pointer}textarea{resize:vertical}* html textarea{margin-top:-2px}*:first-child+html textarea{margin-top:-2px}abbr,acronym{border:0}address,caption,cite,code,dfn,em,var{font-style:normal;font-weight:normal}caption{text-align:left}code,kbd,pre,samp,tt{font-family:monospace;line-height:1.0}*+html code,*+html kbd,*+html pre,*+html samp,*+html tt{font-size:108%}q:after,q:before{content:''}article,aside,audio,canvas,details,figcaption,figure,footer,header,hgroup,mark,menu,nav,section,summary,time,video{background:transparent;border:0;font-size:100%;margin:0;outline:0;padding:0;vertical-align:baseline}article,aside,details,figcaption,figure,footer,header,hgroup,menu,nav,section{display:block}

html {
	font-size: 62.5%;
	-webkit-text-size-adjust: 100%;
	-ms-text-size-adjust:     100%;
}

body {
	background: #ffffff;
	color: #000000;
	font-family: "Noto Sans JP";
	font-weight: 400;
	line-height: 1.5;
	* {
		box-sizing: border-box;
	}
}

/* a */
a {
	color: #448aca;
	text-decoration: underline;
	&:hover {
		color: #448aca;
		text-decoration: none;
	}
}


/* Modules
--------------------- */

/* .pc-only */
@include max-screen($breakpoint-sp) {
	.pc-only {
		display: none!important;
	}
}

/* .tb-only */
.tb-only {
	display: none!important;
	@include max-screen($breakpoint-tb) {
		display: block!important;
	}
}
@include max-screen($breakpoint-tb) {
	br.tb-only {
		display: inline!important;
	}
}

/* .sp-only */
.sp-only {
	display: none!important;
	@include max-screen($breakpoint-sp) {
		display: block!important;
	}
}
@include max-screen($breakpoint-sp) {
	br.sp-only {
		display: inline!important;
	}
}

/* .align-right */
.align-right {
	text-align: right;
}

/* .bold */
.bold {
	font-weight: 700;
}

/* .mb0 */
.mb0 {
	margin-bottom: 0!important;
}


/* #layout
--------------------- */

#layout {
	overflow: hidden;
	padding-top: 100px;
	position: relative;
	width: 100%;
	@include max-screen($breakpoint-pc) {
		padding-top: 8.33vw;
	}
	@include max-screen($breakpoint-tb) {
		padding-top: 12vw;
	}
}


/* #header
--------------------- */

#header {
	background: #000000;
	height: 100px;
	left: 0;
	padding: 0 20px;
	position: fixed;
	top: 0;
	width: 100%;
	z-index: 2;
	@media screen and (max-width: 1400px) {
		height: calc(100 / 1400 * 100vw);
		padding: 0 calc(20 / 1400 * 100vw);
	}
	@include max-screen($breakpoint-tb) {
		height: 12vw;
		padding: 0 2vw;
	}
	> div {
		margin: 0 auto;
		max-width: 1400px;
		position: relative;
	}
}

/* .header__logo */
.header__logo {
	left: 0;
	position: absolute;
	top: 12px;
	z-index: 1;
	@media screen and (max-width: 1400px) {
		top: calc(12 / 1400 * 100vw);
		img {
			height: calc(76 / 1400 * 100vw);
		}
	}
	@include max-screen($breakpoint-tb) {
		top: 2vw;
		img {
			height: 8vw;
		}
	}
}

/* .header__btn-menu */
.header__btn-menu {
	display: none;
	@include max-screen($breakpoint-tb) {
		cursor: pointer;
		display: block;
		height: 12vw;
		position: fixed;
		right: 0;
		top: 0;
		width: 12vw;
		z-index: 9;
	}
}


/* .gnav
--------------------- */

.gnav {
	position: absolute;
	right: 0;
	top: 40px;
	@media screen and (max-width: 1400px) {
		top: calc(40 / 1400 * 100vw);
	}
	@include max-screen($breakpoint-tb) {
		background: #000000;
		display: none;
		height: 100vh;
		left: 0;
		position: fixed;
		top: 0;
		width: 100%;
	}
	> div {
		@extend .pseudo-table;
	}
	ul {
		@extend .clearfix;
		li {
			float: left;
			line-height: 1;
			margin-right: 40px;
			position: relative;
			@media screen and (max-width: 1400px) {
				margin-right: calc(40 / 1400 * 100vw);
			}
			@include max-screen($breakpoint-tb) {
				display: block;
				float: none;
				margin: 0 0 5vh;
				&:first-child {
					display: block;
				}
				&:last-child {
					margin-bottom: 0;
				}
			}
			&.last {
				margin-right: 0;
			}
			a {
				color: #ffffff;
				font-size: 2rem;
				font-weight: 700;
				text-decoration: none;
				@media screen and (max-width: 1400px) {
					font-size: calc(20 / 1400 * 100vw);
				}
				@include max-screen($breakpoint-tb) {
					font-size: 5vw;
				}
			}
			a:hover::after,
			&.current a::after {
				background: #00B9FF;
				content: '';
				height: 4px;
				left: 0;
				position: absolute;
				top: 56px;
				width: 100%;
				@media screen and (max-width: 1400px) {
					top: calc(56 / 1400 * 100vw);
				}
				@include max-screen($breakpoint-tb) {
					display: none;
				}
			}
		}
	}
}


/* .page-header
--------------------- */

.page-header {
	background: #FBFBFB;
	border-bottom: 5px solid #E6E6E6;
	font-size: 3.4rem;
	font-weight: 700;
	height: 150px;
	margin-bottom: 80px;
	position: relative;
	> div {
		@extend .pseudo-table;
	}
	h1 {
		font-weight: 700;
	}
}


/* .topic-path
--------------------- */

.topic-path {
	font-size: 1.4rem;
	left: 0;
	line-height: 1;
	padding: 20px;
	position: absolute;
	text-align: left;
	top: 0;
	width: 100%;
	ul {
		margin: 0 auto;
		max-width: 1160px;
	}
	li {
		display: inline;
		&::after {
			content: ' >';
		}
		&:last-child::after {
			content: '';
		}
	}
	a {
		color: #000000;
		text-decoration: none;
		&:hover {
			text-decoration: underline;
		}
	}
}


/* #contents
--------------------- */

#contents {
	width: 100%;
	@extend .clearfix;
}


/* .visual-animate
--------------------- */

.visual-animate {
	height: 100%;
	position: relative;
	width: 100%;
	z-index: 1;
}

/* .visual-animate__layer */
.visual-animate__layer {
	height: 100%;
	overflow: hidden;
	width: 100%;
}

/* .visual-animate__layer__inner */
.visual-animate__layer__inner {
	height: 100%;
	margin: 0 auto;
	overflow: hidden;
	position: relative;
	width: 100%;
}

/* .visual-animate__bg */
.visual-animate__bg {
	background-position: 50% 50%;
	background-repeat: no-repeat;
	background-size: cover;
	height: 100%;
	width: 100%;
}
.visual-animate__bg__layer {
	display: table;
	height: 100%;
	width: 100%;
}
.visual-animate__bg__layer__inner {
	display: table-cell;
	height: 100%;
	text-align: center;
	vertical-align: middle;
	width: 100%;
}

/* .visual-animate__contents */
.visual-animate__contents {
}

.visual-animate {
	ul {
		/* .slide */
		&.slide {
			height: 100%;
			left: 0;
			position: absolute;
			top: 0;
			width: 100%;
			@extend .clearfix;
			> li {
				float: left;
				height: 100%;
				position: relative;
				width: 100%;
			}
		}
		
		/* .fade */
		&.fade {
			height: 100%;
			width: 100%;
			> li {
				height: 100%;
				left: 0;
				position: absolute;
				top: 0;
				width: 100%;
			}
		}
		
		figure {
			display: none;
		}
	}
} 

/* .btn */
.visual-animate__btn-prev,
.visual-animate__btn-next {
	display: table;
	height: 100%;
	position: absolute;
	top: 0;
	z-index: 9999;
	width: 60px;
	@include max-screen($breakpoint-sp) {
		width: 10vw;
	}
}
.visual-animate__btn-prev,
.visual-animate__btn-next {
	> div {
		display: table-cell;
		height: 100%;
		vertical-align: middle;
		width: 100%;
	}
	span {
		cursor: pointer;
		display: inline-block;
		width: 100%;
	}
	img {
		-webkit-transform: translate(0, 0);
				transform: translate(0, 0);
		-webkit-transition: .1s ease-in-out;
				transition: .1s ease-in-out;
		width: 100%;
	}
}
.visual-animate__btn-prev {
	left: 10px;
	span:hover img {
		-webkit-transform: translate(-10px, 0);
				transform: translate(-10px, 0);
		@include max-screen($breakpoint-sp) {
			-webkit-transform: translate(0, 0);
					transform: translate(0, 0);
		}
	}
}
.visual-animate__btn-next {
	right: 10px;
	span:hover img {
		-webkit-transform: translate(10px, 0);
				transform: translate(10px, 0);
		@include max-screen($breakpoint-sp) {
			-webkit-transform: translate(0, 0);
					transform: translate(0, 0);
		}
	}
}

/* ul.visual-animate__dot */
ul.visual-animate__dot {
	padding: 10px 0 0;
	text-align: center;
	width: 100%;
	li {
		background: #ccc;
		border-radius: 10px;
		cursor: pointer;
		display: inline-block;
		height: 20px;
		margin-right: 10px;
		width: 20px;
		&:last-child {
			margin-right: 0;
		}
		&.current {
			background: #39c;
		}
	}
}

/* .visual-animate__num */
.visual-animate__num {
	bottom: 0;
	position: absolute;
	text-align: center;
	width: 100%;
	span {
		background: rgba(255,255,255,0.8);
		display: inline-block;
		padding: 2px 5px;
	}
}

/* .visual-animate__overlay */
.visual-animate__overlay {
	background: rgba(255,255,255,0.8);
	height: 100%;
	position: absolute;
	top: 0;
	width: -webkit-calc((100% - 600px) / 2);
	width:         calc((100% - 600px) / 2);
	z-index: 9998;
	@media only screen and (max-width: 1000px) {
		display: none;
	}
}
.visual-animate__overlay--left {
	left: 0;
}
.visual-animate__overlay--right {
	right: 0;
}


/* 個別のスタイル
--------------------- */

/* .slider-zoom-fade */
.slider-zoom-fade {
	height: 450px;
	margin: 0 auto 100px;
	max-width: 600px;
	.visual-animate__layer {
		ul {
			height: 100%;
			width: 100%;
			@include max-screen($breakpoint-sp) {
				height: 75vw;
			}
			> li {
				height: 100%;
				left: 0;
				position: absolute;
				top: 0;
				width: 100%;
			}
		}
	}
	@include max-screen($breakpoint-sp) {
		height: 75vw;
	}
}

/* .slider-slide */
.slider-slide {
	height: 450px;
	margin: 0 auto 100px;
	max-width: 600px;
	@include max-screen($breakpoint-sp) {
		height: 75vw;
		.visual-animate__layer {
			ul {
				height: 75vw;
			}
		}
	}
}

/* .slider-fade */
.slider-fade {
	height: 450px;
	margin: 0 auto 100px;
	max-width: 600px;
	@include max-screen($breakpoint-sp) {
		height: 75vw;
		.visual-animate__layer {
			ul {
				height: 75vw;
			}
		}
	}
}

/* .slider-no-cover */
.slider-no-cover {
	margin: 0 auto 100px;
	.visual-animate__layer {
		ul {
			height: auto;
			> li {
				background: #fafafa;
				border: 1px solid #ccc;
				margin-right: 20px;
				padding: 20px;
				@extend .clearfix;
				figure {
					display: block;
					float: left;
					width: 48%;
					img {
						width: 100%;
					}
				}
				p {
					color: #000000;
					float: right;
					font-size: 1rem;
					text-align: left;
					width: 48%;
				}
			}
		}
	}
	.visual-animate__layer__inner {
		overflow: visible;
		max-width: 600px;
	}
	.visual-animate__bg__layer__inner {
		text-align: left;
		vertical-align: top;
	}
}

/* .slider-full-screen */
.slider-full-screen {
	height: 100vh;
	margin-bottom: 100px;
	ul.visual-animate__dot {
		bottom: 20px;
		position: absolute;
	}
}

/* .slider-responsive-image */
.slider-responsive-image {
	height: 450px;
	margin: 0 auto 100px;
	max-width: 600px;
	@include max-screen($breakpoint-sp) {
		height: 66.67vw;
		.visual-animate__layer ul {
			height: 66.67vw;
		}
	}
}

/* .visual-animate__thumbnail */
.visual-animate__thumbnail {
	margin: 0 auto 100px;
	max-width: 600px;
	@extend .clearfix;
	li {
		float: left;
		margin: 0 2% 2% 0;
		width: 23.5%;
		img {
			width: 100%;
		}
		&:nth-child(4n) {
			margin-right: 0;
		}
		&.current {
			box-shadow: 0 0 0 3px #ff0000;
		}
	}
}

/* .zoom */
.zoom {
	display: inline-block;
	position: relative;
}

/* deactivate */
.deactivate {
	.visual-animate {
		height: 100%!important;
		ul {
			height: 100%;
			li {
				display: none!important;
				height: 100%!important;
				&:first-child {
					display: block!important;
				}
			}
		}
	}
	.visual-animate__layer {
		height: 100%;
	}
	.visual-animate__layer__inner {
		height: 100%;
	}
	.visual-animate__contents {
		height: 100%;
	}
	.visual-animate__btn-prev,
	.visual-animate__btn-next {
		display: none;
	}
}


/* .overlay
--------------------- */

.overlay {
	background: rgba(0,0,0,0.5);
	height: -webkit-calc(100% - 190px);
	height:         calc(100% - 190px);
	left: 0;
	padding: 20px 60px 0;
	position: fixed;
	top: 0;
	width: 100%;
	z-index: 3;
	&.loading {
		background: rgba(0,0,0,0.5) url("../img/loading_wht.svg") 50% 50% no-repeat;
	}
	> div {
		background-position: 50% 50%;
		background-repeat: no-repeat;
		background-size: contain;
		height: 100%;
		width: 100%;
	}
}
.overlay__btn-close {
	background: url("../img/overlay_ico_close_wht.svg") 50% 50% no-repeat;
	background-size: cover;
	cursor: pointer;
	height: 30px;
	position: absolute;
	right: 20px;
	top: 20px;
	width: 30px;
	z-index: 1;
}
.overlay__btn-prev {
	background: url("../img/overlay_ico_prev.svg") 50% 50% no-repeat;
	background-size: auto 160px;
	cursor: pointer;
	height: 160px;
	left: 0;
	margin-top: -80px;
	position: absolute;
	top: 50%;
	width: 60px;
}
.overlay__btn-next {
	background: url("../img/overlay_ico_next.svg") 50% 50% no-repeat;
	background-size: auto 160px;
	cursor: pointer;
	height: 160px;
	margin-top: -80px;
	position: absolute;
	right: 0;
	top: 50%;
	width: 60px;
}


/* ------------------------------------------
 * post-content
------------------------------------------ */


/* General
--------------------- */

.post-content {
	color: #000000;
	font-family: -apple-system, "游ゴシック", "Yu Gothic", "游ゴシック体", YuGothic, "メイリオ", Meiryo, "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", sans-serif;
	font-size: 16px;
	line-height: 2;
	margin: 0 auto 80px;
	max-width: 1200px;
	padding: 0 20px;
	@include max-screen($breakpoint-tb) {
		padding: 0 4vw;
	}
	> *:first-child {
		margin-top: 0!important;
	}
	
	/* img */
	img {
		height: auto;
		max-width: 100%;
	}
	
	/* p */
	p {
		font-size: 16px;
		line-height: 2;
		margin: 0 0 40px;
		padding: 0;
		@include max-screen($breakpoint-sp) {
			margin: 0 0 20px;
		}
	}

	.small {
		font-size: 12px;
	}
	.large {
		font-size: 24px;
	}
	.x-large {
		font-size: 32px;
	}

	/* h- */
	h2 {
		font-size: 30px;
		font-weight: 700;
		line-height: 1.5;
		margin: 80px 0 40px;
		padding-bottom: 10px;
		position: relative;
		&::after {
			content: '';
			background: -webkit-linear-gradient(right, #00F6FF 0%, #FF00DD 20%, #DFFF00 40%, #000000 60%, #000000 100%);
			background:        -webkit-gradient(linear, left top, right top, color-stop(0%, #00F6FF), color-stop(20%, #FF00DD), color-stop(40%, #DFFF00), color-stop(60%, #000000), color-stop(100%, #000000));
			background:         linear-gradient(to right, #00F6FF 0%, #FF00DD 20%, #DFFF00 40%, #000000 60%, #000000 100%);
			bottom: 0;
			height: 4px;
			left: 0;
			position: absolute;
			width: 100%;
		}
		time {
			display: block;
			font-size: 1.6rem;
			margin-bottom: 8px;
		}
	}
	h3 {
		font-size: 26px;
		font-weight: 700;
		line-height: 1.5;
		margin: 40px 0;
	}
	h4 {
		border-bottom: 1px solid #CCCCCC;
		font-size: 1.6rem;
		font-weight: 700;
		line-height: 1.5;
		margin: 40px 0 20px;
		padding: 0 0 10px;
	}
	
	/* em */
	em {
		font-style: italic;
		font-weight: inherit;
	}
	
	/* del */
	del {
		color: #FF0000;
	}
	
	/* ul */
	ul {
		list-style-type: disc;
		margin: 0 0 40px;
		padding: 0 0 0 2em;
		@include max-screen($breakpoint-sp) {
			margin: 0 0 20px;
		}
		li {
			font-size: 14px;
			margin: 0 0 10px;
			&:last-child {
				margin: 0;
			}
			> *:first-child {
				margin-top: 0!important;
			}
			> *:last-child {
				margin-bottom: 0!important;
			}
		}
	}
	
	/* ol */
	ol {
		list-style-type: decimal;
		margin: 0 0 40px;
		padding: 0 0 0 2em;
		@include max-screen($breakpoint-sp) {
			margin: 0 0 20px;
		}
		li {
			font-size: 14px;
			margin: 0 0 10px;
			&:last-child {
				margin: 0;
			}
			> *:first-child {
				margin-top: 0!important;
			}
			> *:last-child {
				margin-bottom: 0!important;
			}
		}
	}
	
	/* table */
	table {
		font-size: 16px;
		margin: 0 0 40px;
		width: 100%;
		@include max-screen($breakpoint-sp) {
			margin: 0 0 20px;
			width: 600px;
		}
		th {
			background-color: #F5F5F5;
			border-bottom: 1px solid #ffffff;
			font-size: 100%;
			font-weight: 700;
			line-height: 2;
			padding: 20px 40px;
			vertical-align: top;
			white-space: nowrap;
			min-width: 220px;
			> *:first-child {
				margin-top: 0!important;
			}
			> *:last-child {
				margin-bottom: 0!important;
			}
		}
		td {
			background-color: #FFFFFF;
			border-bottom: 1px solid #E8E8E8;
			font-size: 100%;
			line-height: 2;
			padding: 20px 40px;
			vertical-align: top;
			> *:first-child {
				margin-top: 0!important;
			}
			> *:last-child {
				margin-bottom: 0!important;
			}
		}
		tr:last-child td {
			border-bottom: 0;
		}
	}
	@include max-screen($breakpoint-sp) {
		.table {
			overflow: auto;
			-webkit-overflow-scrolling: touch;
			width: 100%;
			table {
				th {
					padding: 10px;
				}
				td {
					padding: 10px;
				}
			}
		}
	}
	
	/* blockquote */
	blockquote {
		background-color: #F9F9F9;
		border: 2px dashed #D1D1D1;
		color: #000000;
		font-size: 14px;
		font-style: italic;
		margin: 0 0 40px;
		padding: 18px;
		> *:first-child {
			margin-top: 0!important;
		}
		> *:last-child {
			margin-bottom: 0!important;
		}
		@include max-screen($breakpoint-sp) {
			margin: 0 0 20px;
		}
		a {
			color: #666666;
		}
		p {
			font-size: 14px;
			margin-bottom: 20px;
		}
	}
	
	/* hr */
	hr {
		background: #CCCCCC;
		border: 0;
		display: block;
		height: 1px;
		margin: 0 0 40px;
		@include max-screen($breakpoint-sp) {
			margin: 0 0 20px;
		}
	}
	
	
	/* .align-
	--------------------- */
	
	.alignleft {
		float: left;
		height: auto;
		margin: 0 40px 40px 0;
		max-width: 100%;
		@include max-screen($breakpoint-sp) {
			display: block;
			float: none;
			margin: 0 auto 20px;
		}
	}
	.alignright {
		float: right;
		height: auto;
		margin: 0 0 40px 40px;
		max-width: 100%;
		@include max-screen($breakpoint-sp) {
			display: block;
			float: none;
			margin: 0 auto 20px;
		}
	}
	.aligncenter {
		clear: both;
		display: block;
		height: auto;
		margin: 0 auto 40px;
		max-width: 100%;
		@include max-screen($breakpoint-sp) {
			margin: 0 auto 20px;
		}
	}
	
	
	/* .wp-caption
	--------------------- */
	
	.wp-caption {
		background: transparent;
		border: none;
		color: #000000;
		margin: 0 0 40px;
		max-width: 100%;
		padding: 0;
		text-align: left;
		&.alignleft {
			margin: 0 40px 40px 0;
		}
		&.alignright {
			margin: 0 0 40px 40px;
		}
		&.aligncenter {
			margin: 0 auto 40px;
		}
		@include max-screen($breakpoint-sp) {
			margin: 0 0 20px;
			&.alignleft {
				margin: 0 20px 20px 0;
			}
			&.alignright {
				margin: 0 0 20px 20px;
			}
			&.aligncenter {
				margin: 0 auto 20px;
			}
		}
	}
	.wp-caption-dt {
		margin: 0;
		padding: 0;
	}
	.wp-caption .wp-caption-text,
	.wp-caption-dd {
		font-size: 12px;
		font-style: normal;
		line-height: 1.5;
		margin: 0;
		padding: 10px 0 0;
		text-align: center;
	}
	
	/* iframe */
	.mce-object-iframe {
		border: 0;
		width: 100%;
	}
}


/* COMMON
--------------------- */

/* .form */
.post-content table.form {
	margin-bottom: 40px;
	text-align: left;
	width: 100%;
	@include max-screen($breakpoint-tb) {
		margin-bottom: 20px;
	}
	th {
		background: 0;
		font-size: 16px;
		font-weight: 700;
		padding: 10px 0;
		vertical-align: top;
		min-width: auto;
		@include max-screen($breakpoint-tb) {
			//display: block;
			padding: 5px 0;
		}
		label {
			display: block;
			padding: 4px 0;
			@include max-screen($breakpoint-tb) {
				padding: 0;
			}
		}
	}
	td {
		border-bottom: 0;
		font-size: 16px;
		padding: 10px 0;
		position: relative;
		vertical-align: top;
		@include max-screen($breakpoint-tb) {
			//display: block;
			padding: 5px 0;
		}
	}
	input {
		width: 100%;
	}
	input,
	select {
		background: #FBFBFB;
		border: 1px solid #ccc;
		height: 40px;
		padding: 0 10px;
		@include max-screen($breakpoint-tb) {
			height: 32px;
		}
		&.w110 {
			width: 110px;
		}
		&.w150 {
			width: 150px;
		}
	}
	textarea {
		background: #FBFBFB;
		border: 1px solid #ccc;
		height: 200px;
		padding: 10px;
		width: 100%;
	}
	.form-error {
		background: #ff0000;
		color: #fff;
		font-size: 12px;
		left: -10px;
		line-height: 1;
		margin-top: 5px;
		padding: 5px;
		position: absolute;
		top: -10px;
		&::after {
			border-left: 3px solid transparent;
			border-right: 3px solid transparent;
			border-top: 6px solid #ff0000;
			bottom: -6px;
			content: '';
			left: 20px;
			position: absolute;
		}
	}
}
.form__btn-change-date {
	background: #000;
	color: #fff;
	padding: 0 10px;
	&:hover {
		opacity: 0.5;
	}
}

/* .buttons */
.post-content .buttons {
	position: relative;
	text-align: center;
	button {
		background: #000;
		color: #ffffff;
		display: inline-block;
		font-size: 20px;
		font-weight: 700;
		line-height: 1;
		padding: 20px 0;
		text-align: center;
		transition: 0.3s ease-in-out;
		max-width: 240px;
		width: 100%;
		&.buttons__send {
			background: #DC3131;
		}
		&.buttons__cancel {
			background: 0;
			color: #DC3131;
			position: absolute;
			right: 0;
			top: 0;
			width: auto;
			@include max-screen($breakpoint-tb) {
				display: block;
				font-size: 16px;
				margin: 20px 0 0 auto;
				position: relative;
				width: 80px;
			}
		}
		&:hover {
			opacity: 0.5;
		}
	}
	ul {
		list-style: none;
		margin: 0;
		padding: 0;
		@extend .clearfix;
	}
	li {
		float: left;
		margin: 0 4% 0 0;
		padding: 0;
		width: 48%;
		&:last-child {
			margin-right: 0;
		}
		button {
			cursor: pointer;
			width: 100%;
		}
	}
}



/* TOP
--------------------- */

.top {
	background: url("../img/top/visual_mask.svg") 0% 0% repeat, url("../img/top/visual_bg_01.jpg") 50% 50% no-repeat;
	background-size: auto, cover;
	height: 100vh;
	width: 100%;
	> div {
		background: rgba(0,0,0,0.64);
		@extend .pseudo-table;
		> div {
			padding-bottom: 190px;
			@include max-screen($breakpoint-tb) {
				padding-bottom: 0;
			}
		}
	}
}

/* .top__logo */
.top__logo {
	margin-bottom: 60px;
	@include max-screen($breakpoint-tb) {
		margin-bottom: 0;
		img {
			width: 40vw;
		}
	}
	@include max-screen($breakpoint-sp) {
		img {
			width: 60vw;
		}
	}
}

/* .top__nav */
.top__nav {
	@include max-screen($breakpoint-tb) {
		background: #000000;
		display: none;
		height: 100vh;
		left: 0;
		position: fixed;
		top: 0;
		width: 100%;
		z-index: 1;
	}
	> div {
		@extend .pseudo-table;
	}
	li {
		display: inline-block;
		margin-right: 40px;
		&:first-child {
			display: none;
		}
		&.last {
			margin-right: 0;
		}
		@include max-screen($breakpoint-tb) {
			display: block;
			margin: 0 0 5vh;
			&:first-child {
				display: block;
			}
			&:last-child {
				margin-bottom: 0;
			}
		}
		a {
			color: #ffffff;
			font-size: 2rem;
			font-weight: 700;
			line-height: 1;
			text-decoration: none;
			@include max-screen($breakpoint-tb) {
				font-size: 5vw;
			}
		}
	}
}

/* .top__nav__logo */
.top__nav__logo {
	display: none;
	@include max-screen($breakpoint-tb) {
		display: block;
		left: 2vw;
		position: absolute;
		top: 2vw;
		z-index: 1;
		img {
			height: 8vw;
		}
	}
}

/* .top__news */
.top__news {
	padding: 0 20px;
	> div {
		border: 1px solid #fff;
		height: 30px;
		margin: 60px auto 0;
		max-width: 600px;
		@extend .clearfix;
	}
}
.top__news__hd {
	background: #fff;
	color: #000;
	float: left;
	font-size: 1.6rem;
	height: 100%;
	line-height: 1;
	padding-top: 6px;
	width: 100px;
}
.top__news__content {
	color: #fff;
	float: left;
	font-size: 1.6rem;
	height: 100%;
	line-height: 1;
	text-align: left;
	width: -webkit-calc(100% - 100px);
	width:         calc(100% - 100px);
	> p {
		display: none;
		margin: 0;
		padding: 6px 10px 0;
	}
}

/* .top__gallery */
.top__gallery {
	background: rgba(0,0,0,0.5);
	bottom: 0;
	height: 190px;
	left: 0;
	margin: 0 auto;
	padding: 20px 60px;
	position: absolute;
	width: 100%;
	@include max-screen($breakpoint-tb) {
		display: none;
	}
	.visual-animate__layer {
		ul {
			li {
				background: #000000;
				cursor: pointer;
				margin-right: 20px;
			}
			@include max-screen($breakpoint-sp) {
				height: 60vw;
			}
		}
	}
	.visual-animate__layer__inner {
		overflow: visible;
		max-width: 200px;
		@include max-screen($breakpoint-sp) {
			max-width: 100%;
		}
	}
	.visual-animate__btn-prev {
		left: -60px;
		@include max-screen($breakpoint-sp) {
			left: -10vw;
		}
	}
	.visual-animate__btn-next {
		right: -60px;
		@include max-screen($breakpoint-sp) {
			right: -10vw;
		}
	}
	.visual-animate__btn-prev,
	.visual-animate__btn-next {
		img {
			width: 30%;
		}
	}
}
.top__gallery__large {
	display: none;
}


/* GUIDE
--------------------- */

/* .guide__slider */
.guide__slider {
	height: 460px;
	margin-bottom: 40px;
	@include max-screen($breakpoint-tb) {
		height: 70.375vw;
	}
	> div {
		height: 460px;
		position: absolute;
		left: 0;
		width: 100%;
		@include max-screen($breakpoint-tb) {
			height: 70.375vw;
			position: relative;
		}
	}
	.visual-animate__layer {
		ul {
			box-sizing: content-box;
			list-style: none;
			padding: 0 0 10vw;
			li {
				margin: 0 80px 0 0;
				@include max-screen($breakpoint-sp) {
					margin-right: 0;
				}
			}
		}
		p {
			bottom: -2.21em;
			font-size: 1.4rem;
			line-height: 1.5;
			margin: 0;
			position: absolute;
			text-align: center;
			width: 100%;
		}
	}
	.visual-animate__layer__inner {
		height: 420px;
		max-width: 640px;
		overflow: visible;
		@include max-screen($breakpoint-tb) {
			height: 60.375vw;
		}
		@include max-screen($breakpoint-sp) {
			max-width: 100%;
		}
	}
	.visual-animate__btn-prev {
		height: 420px;
		left: 50%;
		margin-left: -390px;
		text-align: center;
		@include max-screen($breakpoint-tb) {
			height: 60.375vw;
		}
		@include max-screen($breakpoint-sp) {
			left: -4vw;
			margin-left: 0;
			width: 10vw;
			span {
				background: rgba(0,0,0,0.8);
				padding: 4vw 0;
			}
		}
	}
	.visual-animate__btn-next {
		height: 420px;
		right: 50%;
		margin-right: -390px;
		text-align: center;
		@include max-screen($breakpoint-tb) {
			height: 60.375vw;
		}
		@include max-screen($breakpoint-sp) {
			margin-right: 0;
			right: -4vw;
			width: 10vw;
			span {
				background: rgba(0,0,0,0.8);
				padding: 4vw 0;
			}
		}
	}
	.visual-animate__btn-prev,
	.visual-animate__btn-next {
		img {
			width: 50%;
			@include max-screen($breakpoint-sp) {
				width: 20%;
			}
		}
	}
	.visual-animate__overlay {
		background: rgba(255,255,255,0.8);
		width: -webkit-calc((100% - 640px) / 2 - 80px);
		width:         calc((100% - 640px) / 2 - 80px);
	}
}


/* reservation
--------------------- */

.reservation__icons {
	padding: 0!important;
	text-align: left;
	li {
		display: inline-block;
		font-size: 18px!important;
		font-weight: 700;
		list-style: none;
		margin-right: 40px!important;
		@include max-screen($breakpoint-sp) {
			display: block;
			margin-right: 0!important;
		}
	}
	img {
		margin-right: 10px;
	}
}

.calendar {
	position: relative;
	table {
		width: 600px;
		thead {
			th {
				background: #F9F9F9;
				border-bottom: 0;
				line-height: 1;
				min-width: inherit;
				padding: 20px;
				position: relative;
				text-align: center;
				vertical-align: middle;
				&:last-child {
					border-right: 1px solid #F9F9F9;
				}
				@include max-screen($breakpoint-sp) {
					padding: 20px 0!important;
				}
				span {
					display: inline-block;
					width: 33%;
				}
				a,
				span {
					color: #000000;
					cursor: pointer;
					display: inline-block;
					text-decoration: none;
					width: 33%;
					&.calendar__prev {
						text-align: left;
					}
					&.calendar__next {
						text-align: right;
					}
				}
			}
			tr:nth-child(1) {
				th {
					font-size: 30px;
					width: 100%;
					@include max-screen($breakpoint-sp) {
						font-size: 20px;
					}
					&::after {
						background: #DBDBDB;
						bottom: 0;
						content: "";
						height: 1px;
						left: 20px;
						position: absolute;
						width: -webkit-calc(100% - 40px);
						width:         calc(100% - 40px);
					}
				}
			}
			tr:nth-child(2) {
				th {
					font-size: 20px;
					width: 14.28%;
					@include max-screen($breakpoint-sp) {
						font-size: 18px;
					}
					&::after {
						background: #DBDBDB;
						content: "";
						height: -webkit-calc(100% - 40px);
						height:         calc(100% - 40px);
						position: absolute;
						right: 0;
						top: 20px;
						width: 1px;
					}
					&:last-child::after {
						display: none;
					}
				}
			}
		}
		td {
			background-position: 50% 50%;
			background-repeat: no-repeat;
			background-size: 24px 24px;
			border-bottom: 1px solid #F1F1F1!important;
			border-right: 1px solid #F1F1F1;
			height: 85px;
			line-height: 1;
			min-width: inherit;
			text-align: center;
			padding: 0;
			position: relative;
			vertical-align: middle;
			width: 14.28%;
			@include max-screen($breakpoint-sp) {
				background-size: auto 40%;
				height: 60px;
			}
			> time {
				font-size: 20px;
				font-weight: 700;
				left: 10px;
				position: absolute;
				top: 10px;
				@include max-screen($breakpoint-sp) {
					font-size: 18px;
					left: 5px;
					top: 5px;
				}
			}
			&:first-child {
				border-left: 1px solid #F1F1F1!important;
			}
			&.off {
				> time {
					color: #C8C8C8;
				}
			}
			&.closed {
				background-image: url("../img/reservation/reservation_ico_closed.svg");
			}
			&.ok {
				background-image: url("../img/reservation/reservation_ico_ok.svg");
				cursor: pointer;
			}
			&.no {
				background-image: url("../img/reservation/reservation_ico_no.svg");
			}
		}
	}
	&.loading::after {
		background: rgba(255,255,255,0.8) url("../img/loading_blk.svg") 50% 50% no-repeat;
		background-size: 80px 80px;
		content: '';
		height: 100%;
		left: 0;
		position: absolute;
		top: 0;
		width: 100%;
	}
}
.calendar__user {
	color: #0090FF;
	cursor: pointer;
	font-size: 14px;
	line-height: 1.5;
	text-decoration: underline;
}

.overlay--reservation {
	@extend .overlay;
	display: none;
	height: 100%;
	padding: 20px 0;
	@include max-screen($breakpoint-tb) {
		padding: 0;
	}
	> div {
		@extend .pseudo-table;
	}
	@include max-screen($breakpoint-tb) {
		> div {
			display: block;
			> div {
				display: block;
				overflow: auto;
			}
		}
	}
	table.form {
		margin-bottom: 20px;
	}
}

.reservation-overlay-content {
	display: none;
	margin: 0 auto;
	background: #ffffff;
	max-height: calc(100vh - 40px);
	max-width: 640px;
	overflow: auto;
	padding: 40px;
	position: relative;
	@include max-screen($breakpoint-tb) {
		max-height: 100vh;
		max-width: 100%;
		padding: 4vw;
	}
	.overlay__btn-close {
		background-image: url("../img/overlay_ico_close_blk.svg")
	}
}

.reservation-form__agree {
	font-size: 16px;
	margin-bottom: 40px;
	position: relative;
	text-align: center;
	@include max-screen($breakpoint-tb) {
		margin-bottom: 20px;
	}
	input {
		margin-right: 10px;
	}
	.form-error {
		background: #ff0000;
		color: #fff;
		font-size: 12px;
		left: 30%;
		line-height: 1;
		margin-top: 5px;
		padding: 5px;
		position: absolute;
		top: -30px;
		&::after {
			border-left: 3px solid transparent;
			border-right: 3px solid transparent;
			border-top: 6px solid #ff0000;
			bottom: -6px;
			content: '';
			left: 20px;
			position: absolute;
		}
	}
}
.reservation-form__sendmail {
	font-size: 16px;
	margin-bottom: 40px;
	position: relative;
	text-align: center;
	@include max-screen($breakpoint-tb) {
		margin-bottom: 20px;
	}
	input {
		margin-right: 10px;
	}
}
.reservation-overlay-content__message {
	p {
		font-size: 16px;
		line-height: 2;
		margin: 40px 0 0;
		text-align: center;
	}
	.buttons {
		margin-top: 40px;
	}
}
.reservation-overlay-content__message__hd {
	font-size: 20px;
	font-weight: 700;
	text-align: center;
}
.reservation-overlay-content__message__txt1 {
	color: #ff0000;
}
.reservation-calendar {
	max-width: 680px;
	.buttons__no-change-date {
		max-width: 440px!important;
	}
	.calendar td,
	.calendar__user {
		cursor: default!important;
	}
	.calendar td.ok {
		cursor: pointer!important;
	}
}


/* reservation-admin
--------------------- */

.reservation-admin {
	.calendar td.no {
		background: 0;
	}
	.calendar td.closed {
		cursor: pointer;
	}
}


/* cancel
--------------------- */

.cancel .post-content {
	.cancel-txt1 {
		text-align: center;
	}
	.cancel-error {
		color: #ff0000;
		margin-bottom: 20px;
		text-align: center;
	}

	.cancel-form {
		text-align: center;
		span {
			font-weight: 700;
		}
		input {
			border: 1px solid #ccc;
			height: 50px;
			margin-left: 40px;
			padding: 0 10px;
			width: 400px;
		}
		.buttons {
			margin-top: 40px;
		}
	}
	.cancel-hd {
		font-size: 20px;
		font-weight: 700;
		margin-bottom: 40px;
		text-align: center;
	}
}


/* access
--------------------- */

.access {
}
.access-gmap {
	border: 1px solid #ccc;
	display: block;
	height: 400px;
	margin-bottom: 40px;
	width: 100%;
}
.access-sec {
	margin: 0 auto 80px;
}
.access-sec__hd {
	font-size: 20px;
	font-weight: 700;
	margin-bottom: 20px!important;
}


/* contact
--------------------- */

.contact .post-content {
	max-width: 600px;
	table.form th {
		min-width: 220px;
	}
	.contact-agree {
		font-size: 16px;
		margin-bottom: 40px;
		text-align: center;
		input {
			margin-right: 10px;
		}
	}
	.mw_wp_form_confirm .contact-agree {
		display: none;
	}
	.buttons__back {
		margin-right: 20px;
	}
	.contact-complete-hd {
		font-size: 20px;
		font-weight: 700;
		text-align: center;
	}
	.contact-complete-msg {
		text-align: center;
	}
	.form th {
		display: block;
		padding: 0;
	}
	.form td {
		display: block;
		margin-bottom: 20px;
		padding: 0;
	}
}


/* mypage
--------------------- */

.mypage .post-content {
	table th {
		vertical-align: middle;
		width: 150px;
	}
	.mypage-cols {
		@extend .clearfix;
	}
	.mypage-cols__detail {
		float: left;
		width: -webkit-calc(100% - 120px);
		width:         calc(100% - 120px);
		dl {
			line-height: 1.5;
			margin-bottom: 10px;
		}
		dt {
			font-weight: 700;
		}
		dd {
			margin: -1.5em 0 0 10em;
		}
	}
	.mypage-cols__control {
		float: right;
		width: 100px;
		ul {
			list-style: none;
			margin: 0;
			padding: 0;
		}
		li {
			border: 1px solid #C5C5C5;
			cursor: pointer;
			font-size: 14px;
			line-height: 1;
			margin: 0 0 20px;
			padding: 10px 0;
			text-align: center;
			&:last-child {
				margin-bottom: 0;
			}
			&:hover {
				opacity: 0.5;
			}
		}
	}
	.mypage-accordion {
		display: none;
	}
	.mypage-accordion-btn {
		border: 1px solid #D1D1D1;
		cursor: pointer;
		font-size: 14px;
		line-height: 1.5;
		text-align: center;
		&:hover {
			opacity: 0.5;
		}
	}
	
	.mypage-passwd-txt1 {
		text-align: center;
	}

	.mypage-passwd-form {
		text-align: center;
		span {
			font-weight: 700;
		}
		input {
			border: 1px solid #ccc;
			height: 50px;
			margin-left: 40px;
			padding: 0 10px;
			width: 200px;
		}
		.buttons {
			margin-top: 40px;
		}
	}
	.mypage-passwd-hd {
		font-size: 20px;
		font-weight: 700;
		margin-bottom: 40px;
		text-align: center;
	}
	.mypage-passwd-error {
		color: #ff0000;
		margin-bottom: 20px;
		text-align: center;
	}
	
	.mypage-email {
		display: none;
	}
	.mypage-ID {
		display: none;
	}
	
	.mypage-contents {
		&.loading::after {
			background: rgba(255,255,255,0.8) url("../img/loading_blk.svg") 50% 50% no-repeat;
			background-size: 80px 80px;
			content: '';
			height: 100%;
			left: 0;
			position: absolute;
			top: 0;
			width: 100%;
		}
	}
}


/* .page-top
--------------------- */

.page-top {
	background: #000 url(../img/page_top_ico.svg) 50% 50% no-repeat;
	border-radius: 30px;
	cursor: pointer;
	height: 60px;
	position: absolute;
	right: 20px;
	top: -80px;
	width: 60px;
	z-index: 1;
	@include max-screen($breakpoint-sp) {
		right: 4vw;
		top: -80px;;
	}
	&.fixed {
		bottom: 20px;
		position: fixed;
		right: 20px;
		top: inherit;
	}
	&:hover {
		opacity: 1!important;
	}
}


/* #footer
--------------------- */

#footer {
	background: #000;
	padding: 40px 20px;
	position: relative;
	width: 100%;
	@include max-screen($breakpoint-tb) {
		padding: 40px 4vw 20px;
	}
}
.footer__in {
	margin: 0 auto;
	max-width: 1160px;
	position: relative;
}

/* logo */
.footer__logo {
	@include max-screen($breakpoint-tb) {
		margin-bottom: 40px;
		text-align: center;
	}
	img {
		height: 152px;
	}
}

/* nav */
.footer__nav {
	position: absolute;
	right: 0;
	top: 0;
	@include max-screen($breakpoint-tb) {
		display: none;
	}
	ul {
		@extend .clearfix;
	}
	li {
		display: inline;
		font-size: 1.4rem;
		font-weight: 700;
		margin-right: 20px;
		&:last-child {
			margin-right: 0;
		}
	}
	a {
		color: #ffffff;
		text-decoration: none;
		&:hover {
			text-decoration: underline;
		}
	}
}

/* .footer__copyright */
.footer__copyright {
	bottom: 0;
	color: #ffffff;
	font-size: 1.4rem;
	position: absolute;
	right: 0;
	@include max-screen($breakpoint-tb) {
		font-size: 1rem;
		position: relative;
		text-align: center;
	}
}


/* news
--------------------- */

.news-archive {
	margin: 0 auto 136px;
	max-width: 1200px;
	padding-left: 20px;
	padding-right: 20px;
	width: 100%;
	@include max-screen($breakpoint-tb) {
		padding-left: 4vw;
		padding-right: 4vw;
	}
	dl {
		border-bottom: 1px solid #ccc;
		border-top: 1px solid #ccc;
		display: flex;
		font-size: 1.6rem;
		margin-bottom: -1px;
		padding: 32px 16px;
		@include max-screen($breakpoint-sp) {
			flex-direction: column;
			padding: calc(16 / 375 * 100vw) calc(8 / 375 * 100vw);
		}
	}
	dt {
		margin-right: 48px;
		flex-shrink: 0;
		@include max-screen($breakpoint-sp) {
			margin-right: 0;
		}
	}
	dd {
		width: 100%;
	}
}


/* Pagination
--------------------- */

.cmn-pagination {
	margin-top: 48px;
	ul {
		align-items: center;
		display: flex;
		justify-content: center;
	}
	&__item {
		font-size: 1.6rem;
		height: 40px;
		margin: 0 5px;
		text-align: center;
		width: 40px;
		a,
		span {
			background: #000;
			box-shadow: inset 0 0 0 1px rgba($color: #000000, $alpha: .5);
			color: #fff;
			display: block;
			line-height: 40px;
			height: 100%;
			text-decoration: none;
			width: 100%;
			@include max-screen($breakpoint-sp) {
				line-height: calc(40 / 375 * 100vw);
			}
		}
		a {
			&:hover {
				background: #333;
				box-shadow: inset 0 0 0 1px rgba($color: #000000, $alpha: .5);
				color: #FFF;
			}
		}
		&--first,
		&--last,
		&--prev,
		&--next {
			span {
				opacity: 0.25;
			}
		}
		&--first {
			display: none;
			margin: 0 5px 0 0;
		}
		&--last {
			display: none;
			margin: 0 0 0 5px;
		}
		&--prev {
			margin: 0 5px 0 0;
		}
		&--next {
			margin: 0 0 0 5px;
		}
		&--current {
			span.cmn-pagination__sp-txt {
				display: none;
				@include max-screen($breakpoint-sp) {
					background: 0;
					box-shadow: none;
					color: #000;
					display: inline-block;
				}
			}
			span.cmn-pagination__pc-txt {
				background: #fff;
				box-shadow: inset 0 0 0 1px rgba($color: #000000, $alpha: .5);
				color: #000;
				line-height: 40px;
				@include max-screen($breakpoint-sp) {
					display: none;
				}
			}
		}
		&--ellipsis {
			line-height: 40px;
		}
		@include max-screen($breakpoint-sp) {
			display: none;
			font-size: calc(16 / 375 * 100vw);
			height: calc(40 / 375 * 100vw);
			line-height: calc(40 / 375 * 100vw);
			margin: 0;
			width: calc(40 / 375 * 100vw);
			&--first,
			&--last,
			&--prev,
			&--next {
				display: block;
				flex-shrink: 0;
			}
			&--first {
				margin-right: calc(8 / 375 * 100vw);
			}
			&--last {
				margin-left: calc(8 / 375 * 100vw);
			}
			&--current {
				background: 0;
				box-shadow: none;
				display: block;
				line-height: calc(40 / 375 * 100vw);
				width: 100%;
			}
		}
		&--first,
		&--last,
		&--prev,
		&--next,
		&--current,
		&--ellipsis {
			@extend .cmn-pagination__item;
		}
	}
}


/* top-news
--------------------- */

@keyframes news-top {
	0% {
		transform: translate(0, 100%);
	}
	100% {
		transform: translate(0, 0);
	}
}
.news-top {
	background: #fff;
	bottom: 0;
	//padding: 40px 40px 108px;
	padding: 40px 40px 64px;
	position: fixed;
	right: 0;
	transform: translate(0, 100%);
	width: 640px;
	z-index: 9;
	animation: .5s 1s forwards news-top;
	@include max-screen($breakpoint-tb) {
		//padding: calc(40 / 960 * 100vw) calc(40 / 960 * 100vw) calc(108 / 960 * 100vw);
		padding: calc(40 / 960 * 100vw) calc(40 / 960 * 100vw) calc(64 / 960 * 100vw);
		width: 100%;
	}
	@include max-screen($breakpoint-sp) {
		//padding: calc(24 / 375 * 100vw) calc(16 / 375 * 100vw) calc(72 / 375 * 100vw);
		padding: calc(24 / 375 * 100vw) calc(16 / 375 * 100vw) calc(40 / 375 * 100vw);
	}
	&__close {
		cursor: pointer;
		font-size: 1.4rem;
		padding-right: 24px;
		position: absolute;
		right: 16px;
		top: 16px;
		z-index: 1;
		@include max-screen($breakpoint-tb) {
			font-size: calc(14 / 960 * 100vw);
			padding-right: calc(24 / 960 * 100vw);
			right: calc(16 / 960 * 100vw);
			top: calc(16 / 960 * 100vw);
		}
		@include max-screen($breakpoint-sp) {
			font-size: calc(11 / 375 * 100vw);
			padding-right: calc(16 / 375 * 100vw);
			right: calc(8 / 375 * 100vw);
			top: calc(8 / 375 * 100vw);
		}
		&::after {
			background: url("../img/overlay_ico_close_blk.svg") 50% 50% no-repeat;
			background-size: 100% 100%;
			content: '';
			display: block;
			height: 16px;
			position: absolute;
			right: 0;
			top: 50%;
			transform: translate(0, -50%);
			width: 16px;
			@include max-screen($breakpoint-tb) {
				height: calc(16 / 960 * 100vw);
				width: calc(16 / 960 * 100vw);
			}
			@include max-screen($breakpoint-sp) {
				height: calc(12 / 375 * 100vw);
				width: calc(12 / 375 * 100vw);
			}
		}
	}
	time {
		display: block;
		font-size: 1.4rem;
		line-height: 1;
		margin-bottom: 8px;
		text-align: center;
		@include max-screen($breakpoint-tb) {
			font-size: calc(14 * 1.15 / 960 * 100vw);
			margin-bottom: calc(8 / 960 * 100vw);
		}
		@include max-screen($breakpoint-sp) {
			font-size: calc(11 / 375 * 100vw);
			margin-bottom: calc(8 / 375 * 100vw);
		}
	}
	&__title {
		font-size: 2rem;
		font-weight: 700;
		line-height: 1.2;
		margin-bottom: 32px;
		text-align: center;
		@include max-screen($breakpoint-tb) {
			font-size: calc(20 * 1.15 / 960 * 100vw);
			margin-bottom: calc(32 / 960 * 100vw);
		}
		@include max-screen($breakpoint-sp) {
			font-size: calc(14 / 375 * 100vw);
			margin-bottom: calc(16 / 375 * 100vw);
		}
		a {
			color: #000;
			text-decoration: none;
		}
	}
	&__contents {
		display: flex;
		max-height: 248px;
		position: relative;
		figure {
			flex-shrink: 0;
			margin-right: 24px;
			@include max-screen($breakpoint-tb) {
				margin-right: calc(24 / 960 * 100vw);
			}
			@include max-screen($breakpoint-sp) {
				margin-right: calc(16 / 375 * 100vw);
			}
			img {
				max-width: 248px;
				@include max-screen($breakpoint-tb) {
					max-width: calc(248 / 960 * 100vw);
				}
				@include max-screen($breakpoint-sp) {
					max-width: calc(100 / 375 * 100vw);
				}
			}
		}
		&__content {
			overflow: auto;
			width: 100%;
			p {
				font-size: 1.4rem;
				line-height: 1.5;
				@include max-screen($breakpoint-tb) {
					font-size: calc(14 * 1.15 / 960 * 100vw);
				}
				@include max-screen($breakpoint-sp) {
					font-size: calc(11 / 375 * 100vw);
				}
				& + p {
					margin-top: 1em;
				}
			}
			.small {
				font-size: 1.2rem;
			}
			.large {
				font-size: 2.4rem;
			}
			.x-large {
				font-size: 3.2rem;
			}
		}
		&__more {
			bottom: -1.5em;
			font-size: 1.4rem;
			line-height: 1;
			position: absolute;
			right: 0;
			@include max-screen($breakpoint-tb) {
				font-size: calc(14 / 960 * 100vw);
			}
			@include max-screen($breakpoint-sp) {
				font-size: calc(11 / 375 * 100vw);
			}
			a {
				color: #000;
				text-decoration: none;
			}
		}
	}
	&__contact {
		bottom: 0;
		position: absolute;
		right: 0;
		a {
			background: #000;
			color: #fff;
			display: block;
			font-size: 2rem;
			font-weight: 700;
			line-height: 1;
			padding: 20px 24px 20px 60px;
			position: relative;
			text-decoration: none;
			width: 100%;
			@include max-screen($breakpoint-tb) {
				font-size: calc(20 / 960 * 100vw);
				padding: calc(20 / 960 * 100vw) calc(24 / 960 * 100vw) calc(20 / 960 * 100vw) calc(60 / 960 * 100vw);
			}
			@include max-screen($breakpoint-sp) {
				font-size: calc(14 / 375 * 100vw);
				padding: calc(13 / 375 * 100vw) calc(16 / 375 * 100vw) calc(13 / 375 * 100vw) calc(40 / 375 * 100vw);
			}
			&::after {
				background: url("../img/ico_email_wht.svg") 50% 50% no-repeat;
				background-size: 20px 15px;
				content: '';
				display: block;
				height: 15px;
				left: 24px;
				position: absolute;
				top: 50%;
				transform: translate(0, -50%);
				width: 20px;
				@include max-screen($breakpoint-tb) {
					background-size: calc(20 / 960 * 100vw) calc(15 / 960 * 100vw);
					height: calc(15 / 960 * 100vw);
					left: calc(24 / 960 * 100vw);
					width: calc(20 / 960 * 100vw);
				}
				@include max-screen($breakpoint-sp) {
					background-size: calc(20 * 0.8 / 375 * 100vw) calc(15 * 0.8 / 375 * 100vw);
					height: calc(15 * 0.8 / 375 * 100vw);
					left: calc(16 / 375 * 100vw);
					width: calc(20 * 0.8 / 375 * 100vw);
				}
			}
		}
	}
}