@charset "UTF-8";
/* CSS Document */
@import url(https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@400;500;700&display=swap);
.clearfix::after, .footer__nav ul::after, .mypage .post-content .mypage-cols::after, .top__news > div::after, .post-content .buttons ul::after, .visual-animate__thumbnail::after, .slider-no-cover .visual-animate__layer ul > li::after, .visual-animate ul.slide::after, #contents::after, .gnav ul::after {
  clear: both;
  content: "";
  display: block;
}

.ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.cmn-sec {
  margin-left: auto;
  margin-right: auto;
  max-width: 1100px;
  padding-left: 50px;
  padding-right: 50px;
}
@media screen and (max-width: 960px) {
  .cmn-sec {
    padding-left: 4vw;
    padding-right: 4vw;
  }
}

.pseudo-table, .overlay--reservation > div, .top__nav > div, .top > div, .page-header > div, .gnav > div {
  display: table;
  height: 100%;
  width: 100%;
}
.pseudo-table > div, .overlay--reservation > div > div, .top__nav > div > div, .top > div > div, .page-header > div > div, .gnav > div > div {
  display: table-cell;
  height: 100%;
  text-align: center;
  vertical-align: middle;
  width: 100%;
}

/* General
--------------------- */
/* reset */
html {
  height: 100%;
  overflow-y: scroll;
  width: 100%;
}

body {
  height: 100%;
  margin: 0;
  overflow: visible;
  padding: 0;
  vertical-align: top;
  width: 100%;
}

h1, h2, h3, h4, h5, h6 {
  font-size: 100%;
  font-weight: normal;
  margin: 0;
}

p {
  margin: 0;
}

a img, img {
  border: 0;
  vertical-align: middle;
}

strong {
  font-style: normal;
  font-weight: bold;
}

small {
  font-size: 100%;
}

ol, ul {
  list-style-type: none;
}

dd, dl, dt, li, ol, ul {
  margin: 0;
  padding: 0;
}

hr {
  background-color: #CCCCCC;
  border: 0;
  color: #CCCCCC;
  height: 1px;
  margin: 0;
  padding: 0;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
  font-size: 100%;
}

th {
  font-style: normal;
  font-weight: normal;
  margin: 0;
  padding: 0;
  text-align: left;
}

td {
  margin: 0;
  padding: 0;
}

blockquote, div, span {
  margin: 0;
  padding: 0;
}

pre {
  font-family: monospace;
  line-height: 1;
  margin: 0;
  padding: 0;
}

button {
  background: 0;
  border: 0;
  cursor: pointer;
  padding: 0;
  vertical-align: middle;
}

button, fieldset, form, input, label, legend, select, textarea {
  font-family: inherit;
  font-size: 100%;
  font-style: inherit;
  font-weight: inherit;
  margin: 0;
  padding: 0;
  vertical-align: middle;
}

label {
  cursor: pointer;
}

textarea {
  resize: vertical;
}

* html textarea {
  margin-top: -2px;
}

*:first-child + html textarea {
  margin-top: -2px;
}

abbr, acronym {
  border: 0;
}

address, caption, cite, code, dfn, em, var {
  font-style: normal;
  font-weight: normal;
}

caption {
  text-align: left;
}

code, kbd, pre, samp, tt {
  font-family: monospace;
  line-height: 1;
}

* + html code, * + html kbd, * + html pre, * + html samp, * + html tt {
  font-size: 108%;
}

q:after, q:before {
  content: "";
}

article, aside, audio, canvas, details, figcaption, figure, footer, header, hgroup, mark, menu, nav, section, summary, time, video {
  background: transparent;
  border: 0;
  font-size: 100%;
  margin: 0;
  outline: 0;
  padding: 0;
  vertical-align: baseline;
}

article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
  display: block;
}

html {
  font-size: 62.5%;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
}

body {
  background: #ffffff;
  color: #000000;
  font-family: "Noto Sans JP";
  font-weight: 400;
  line-height: 1.5;
}
body * {
  box-sizing: border-box;
}

/* a */
a {
  color: #448aca;
  text-decoration: underline;
}
a:hover {
  color: #448aca;
  text-decoration: none;
}

/* Modules
--------------------- */
/* .pc-only */
@media screen and (max-width: 560px) {
  .pc-only {
    display: none !important;
  }
}
/* .tb-only */
.tb-only {
  display: none !important;
}
@media screen and (max-width: 960px) {
  .tb-only {
    display: block !important;
  }
}

@media screen and (max-width: 960px) {
  br.tb-only {
    display: inline !important;
  }
}
/* .sp-only */
.sp-only {
  display: none !important;
}
@media screen and (max-width: 560px) {
  .sp-only {
    display: block !important;
  }
}

@media screen and (max-width: 560px) {
  br.sp-only {
    display: inline !important;
  }
}
/* .align-right */
.align-right {
  text-align: right;
}

/* .bold */
.bold {
  font-weight: 700;
}

/* .mb0 */
.mb0 {
  margin-bottom: 0 !important;
}

/* #layout
--------------------- */
#layout {
  overflow: hidden;
  padding-top: 100px;
  position: relative;
  width: 100%;
}
@media screen and (max-width: 1200px) {
  #layout {
    padding-top: 8.33vw;
  }
}
@media screen and (max-width: 960px) {
  #layout {
    padding-top: 12vw;
  }
}

/* #header
--------------------- */
#header {
  background: #000000;
  height: 100px;
  left: 0;
  padding: 0 20px;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 2;
}
@media screen and (max-width: 1400px) {
  #header {
    height: 7.1428571429vw;
    padding: 0 1.4285714286vw;
  }
}
@media screen and (max-width: 960px) {
  #header {
    height: 12vw;
    padding: 0 2vw;
  }
}
#header > div {
  margin: 0 auto;
  max-width: 1400px;
  position: relative;
}

/* .header__logo */
.header__logo {
  left: 0;
  position: absolute;
  top: 12px;
  z-index: 1;
}
@media screen and (max-width: 1400px) {
  .header__logo {
    top: 0.8571428571vw;
  }
  .header__logo img {
    height: 5.4285714286vw;
  }
}
@media screen and (max-width: 960px) {
  .header__logo {
    top: 2vw;
  }
  .header__logo img {
    height: 8vw;
  }
}

/* .header__btn-menu */
.header__btn-menu {
  display: none;
}
@media screen and (max-width: 960px) {
  .header__btn-menu {
    cursor: pointer;
    display: block;
    height: 12vw;
    position: fixed;
    right: 0;
    top: 0;
    width: 12vw;
    z-index: 9;
  }
}

/* .gnav
--------------------- */
.gnav {
  position: absolute;
  right: 0;
  top: 40px;
}
@media screen and (max-width: 1400px) {
  .gnav {
    top: 2.8571428571vw;
  }
}
@media screen and (max-width: 960px) {
  .gnav {
    background: #000000;
    display: none;
    height: 100vh;
    left: 0;
    position: fixed;
    top: 0;
    width: 100%;
  }
}
.gnav ul li {
  float: left;
  line-height: 1;
  margin-right: 40px;
  position: relative;
}
@media screen and (max-width: 1400px) {
  .gnav ul li {
    margin-right: 2.8571428571vw;
  }
}
@media screen and (max-width: 960px) {
  .gnav ul li {
    display: block;
    float: none;
    margin: 0 0 5vh;
  }
  .gnav ul li:first-child {
    display: block;
  }
  .gnav ul li:last-child {
    margin-bottom: 0;
  }
}
.gnav ul li.last {
  margin-right: 0;
}
.gnav ul li a {
  color: #ffffff;
  font-size: 2rem;
  font-weight: 700;
  text-decoration: none;
}
@media screen and (max-width: 1400px) {
  .gnav ul li a {
    font-size: 1.4285714286vw;
  }
}
@media screen and (max-width: 960px) {
  .gnav ul li a {
    font-size: 5vw;
  }
}
.gnav ul li a:hover::after, .gnav ul li.current a::after {
  background: #00B9FF;
  content: "";
  height: 4px;
  left: 0;
  position: absolute;
  top: 56px;
  width: 100%;
}
@media screen and (max-width: 1400px) {
  .gnav ul li a:hover::after, .gnav ul li.current a::after {
    top: 4vw;
  }
}
@media screen and (max-width: 960px) {
  .gnav ul li a:hover::after, .gnav ul li.current a::after {
    display: none;
  }
}

/* .page-header
--------------------- */
.page-header {
  background: #FBFBFB;
  border-bottom: 5px solid #E6E6E6;
  font-size: 3.4rem;
  font-weight: 700;
  height: 150px;
  margin-bottom: 80px;
  position: relative;
}
.page-header h1 {
  font-weight: 700;
}

/* .topic-path
--------------------- */
.topic-path {
  font-size: 1.4rem;
  left: 0;
  line-height: 1;
  padding: 20px;
  position: absolute;
  text-align: left;
  top: 0;
  width: 100%;
}
.topic-path ul {
  margin: 0 auto;
  max-width: 1160px;
}
.topic-path li {
  display: inline;
}
.topic-path li::after {
  content: " >";
}
.topic-path li:last-child::after {
  content: "";
}
.topic-path a {
  color: #000000;
  text-decoration: none;
}
.topic-path a:hover {
  text-decoration: underline;
}

/* #contents
--------------------- */
#contents {
  width: 100%;
}

/* .visual-animate
--------------------- */
.visual-animate {
  height: 100%;
  position: relative;
  width: 100%;
  z-index: 1;
}

/* .visual-animate__layer */
.visual-animate__layer {
  height: 100%;
  overflow: hidden;
  width: 100%;
}

/* .visual-animate__layer__inner */
.visual-animate__layer__inner {
  height: 100%;
  margin: 0 auto;
  overflow: hidden;
  position: relative;
  width: 100%;
}

/* .visual-animate__bg */
.visual-animate__bg {
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
  width: 100%;
}

.visual-animate__bg__layer {
  display: table;
  height: 100%;
  width: 100%;
}

.visual-animate__bg__layer__inner {
  display: table-cell;
  height: 100%;
  text-align: center;
  vertical-align: middle;
  width: 100%;
}

/* .visual-animate__contents */
.visual-animate ul {
  /* .slide */
  /* .fade */
}
.visual-animate ul.slide {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}
.visual-animate ul.slide > li {
  float: left;
  height: 100%;
  position: relative;
  width: 100%;
}
.visual-animate ul.fade {
  height: 100%;
  width: 100%;
}
.visual-animate ul.fade > li {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}
.visual-animate ul figure {
  display: none;
}

/* .btn */
.visual-animate__btn-prev,
.visual-animate__btn-next {
  display: table;
  height: 100%;
  position: absolute;
  top: 0;
  z-index: 9999;
  width: 60px;
}
@media screen and (max-width: 560px) {
  .visual-animate__btn-prev,
  .visual-animate__btn-next {
    width: 10vw;
  }
}

.visual-animate__btn-prev > div,
.visual-animate__btn-next > div {
  display: table-cell;
  height: 100%;
  vertical-align: middle;
  width: 100%;
}
.visual-animate__btn-prev span,
.visual-animate__btn-next span {
  cursor: pointer;
  display: inline-block;
  width: 100%;
}
.visual-animate__btn-prev img,
.visual-animate__btn-next img {
  -webkit-transform: translate(0, 0);
  transform: translate(0, 0);
  -webkit-transition: 0.1s ease-in-out;
  transition: 0.1s ease-in-out;
  width: 100%;
}

.visual-animate__btn-prev {
  left: 10px;
}
.visual-animate__btn-prev span:hover img {
  -webkit-transform: translate(-10px, 0);
  transform: translate(-10px, 0);
}
@media screen and (max-width: 560px) {
  .visual-animate__btn-prev span:hover img {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
  }
}

.visual-animate__btn-next {
  right: 10px;
}
.visual-animate__btn-next span:hover img {
  -webkit-transform: translate(10px, 0);
  transform: translate(10px, 0);
}
@media screen and (max-width: 560px) {
  .visual-animate__btn-next span:hover img {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
  }
}

/* ul.visual-animate__dot */
ul.visual-animate__dot {
  padding: 10px 0 0;
  text-align: center;
  width: 100%;
}
ul.visual-animate__dot li {
  background: #ccc;
  border-radius: 10px;
  cursor: pointer;
  display: inline-block;
  height: 20px;
  margin-right: 10px;
  width: 20px;
}
ul.visual-animate__dot li:last-child {
  margin-right: 0;
}
ul.visual-animate__dot li.current {
  background: #39c;
}

/* .visual-animate__num */
.visual-animate__num {
  bottom: 0;
  position: absolute;
  text-align: center;
  width: 100%;
}
.visual-animate__num span {
  background: rgba(255, 255, 255, 0.8);
  display: inline-block;
  padding: 2px 5px;
}

/* .visual-animate__overlay */
.visual-animate__overlay {
  background: rgba(255, 255, 255, 0.8);
  height: 100%;
  position: absolute;
  top: 0;
  width: -webkit-calc((100% - 600px) / 2);
  width: calc((100% - 600px) / 2);
  z-index: 9998;
}
@media only screen and (max-width: 1000px) {
  .visual-animate__overlay {
    display: none;
  }
}

.visual-animate__overlay--left {
  left: 0;
}

.visual-animate__overlay--right {
  right: 0;
}

/* 個別のスタイル
--------------------- */
/* .slider-zoom-fade */
.slider-zoom-fade {
  height: 450px;
  margin: 0 auto 100px;
  max-width: 600px;
}
.slider-zoom-fade .visual-animate__layer ul {
  height: 100%;
  width: 100%;
}
@media screen and (max-width: 560px) {
  .slider-zoom-fade .visual-animate__layer ul {
    height: 75vw;
  }
}
.slider-zoom-fade .visual-animate__layer ul > li {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}
@media screen and (max-width: 560px) {
  .slider-zoom-fade {
    height: 75vw;
  }
}

/* .slider-slide */
.slider-slide {
  height: 450px;
  margin: 0 auto 100px;
  max-width: 600px;
}
@media screen and (max-width: 560px) {
  .slider-slide {
    height: 75vw;
  }
  .slider-slide .visual-animate__layer ul {
    height: 75vw;
  }
}

/* .slider-fade */
.slider-fade {
  height: 450px;
  margin: 0 auto 100px;
  max-width: 600px;
}
@media screen and (max-width: 560px) {
  .slider-fade {
    height: 75vw;
  }
  .slider-fade .visual-animate__layer ul {
    height: 75vw;
  }
}

/* .slider-no-cover */
.slider-no-cover {
  margin: 0 auto 100px;
}
.slider-no-cover .visual-animate__layer ul {
  height: auto;
}
.slider-no-cover .visual-animate__layer ul > li {
  background: #fafafa;
  border: 1px solid #ccc;
  margin-right: 20px;
  padding: 20px;
}
.slider-no-cover .visual-animate__layer ul > li figure {
  display: block;
  float: left;
  width: 48%;
}
.slider-no-cover .visual-animate__layer ul > li figure img {
  width: 100%;
}
.slider-no-cover .visual-animate__layer ul > li p {
  color: #000000;
  float: right;
  font-size: 1rem;
  text-align: left;
  width: 48%;
}
.slider-no-cover .visual-animate__layer__inner {
  overflow: visible;
  max-width: 600px;
}
.slider-no-cover .visual-animate__bg__layer__inner {
  text-align: left;
  vertical-align: top;
}

/* .slider-full-screen */
.slider-full-screen {
  height: 100vh;
  margin-bottom: 100px;
}
.slider-full-screen ul.visual-animate__dot {
  bottom: 20px;
  position: absolute;
}

/* .slider-responsive-image */
.slider-responsive-image {
  height: 450px;
  margin: 0 auto 100px;
  max-width: 600px;
}
@media screen and (max-width: 560px) {
  .slider-responsive-image {
    height: 66.67vw;
  }
  .slider-responsive-image .visual-animate__layer ul {
    height: 66.67vw;
  }
}

/* .visual-animate__thumbnail */
.visual-animate__thumbnail {
  margin: 0 auto 100px;
  max-width: 600px;
}
.visual-animate__thumbnail li {
  float: left;
  margin: 0 2% 2% 0;
  width: 23.5%;
}
.visual-animate__thumbnail li img {
  width: 100%;
}
.visual-animate__thumbnail li:nth-child(4n) {
  margin-right: 0;
}
.visual-animate__thumbnail li.current {
  box-shadow: 0 0 0 3px #ff0000;
}

/* .zoom */
.zoom {
  display: inline-block;
  position: relative;
}

/* deactivate */
.deactivate .visual-animate {
  height: 100% !important;
}
.deactivate .visual-animate ul {
  height: 100%;
}
.deactivate .visual-animate ul li {
  display: none !important;
  height: 100% !important;
}
.deactivate .visual-animate ul li:first-child {
  display: block !important;
}
.deactivate .visual-animate__layer {
  height: 100%;
}
.deactivate .visual-animate__layer__inner {
  height: 100%;
}
.deactivate .visual-animate__contents {
  height: 100%;
}
.deactivate .visual-animate__btn-prev,
.deactivate .visual-animate__btn-next {
  display: none;
}

/* .overlay
--------------------- */
.overlay, .overlay--reservation {
  background: rgba(0, 0, 0, 0.5);
  height: -webkit-calc(100% - 190px);
  height: calc(100% - 190px);
  left: 0;
  padding: 20px 60px 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 3;
}
.overlay.loading, .loading.overlay--reservation {
  background: rgba(0, 0, 0, 0.5) url("../img/loading_wht.svg") 50% 50% no-repeat;
}
.overlay > div, .overlay--reservation > div {
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: contain;
  height: 100%;
  width: 100%;
}

.overlay__btn-close {
  background: url("../img/overlay_ico_close_wht.svg") 50% 50% no-repeat;
  background-size: cover;
  cursor: pointer;
  height: 30px;
  position: absolute;
  right: 20px;
  top: 20px;
  width: 30px;
  z-index: 1;
}

.overlay__btn-prev {
  background: url("../img/overlay_ico_prev.svg") 50% 50% no-repeat;
  background-size: auto 160px;
  cursor: pointer;
  height: 160px;
  left: 0;
  margin-top: -80px;
  position: absolute;
  top: 50%;
  width: 60px;
}

.overlay__btn-next {
  background: url("../img/overlay_ico_next.svg") 50% 50% no-repeat;
  background-size: auto 160px;
  cursor: pointer;
  height: 160px;
  margin-top: -80px;
  position: absolute;
  right: 0;
  top: 50%;
  width: 60px;
}

/* ------------------------------------------
 * post-content
------------------------------------------ */
/* General
--------------------- */
.post-content {
  color: #000000;
  font-family: -apple-system, "游ゴシック", "Yu Gothic", "游ゴシック体", YuGothic, "メイリオ", Meiryo, "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", sans-serif;
  font-size: 16px;
  line-height: 2;
  margin: 0 auto 80px;
  max-width: 1200px;
  padding: 0 20px;
  /* img */
  /* p */
  /* h- */
  /* em */
  /* del */
  /* ul */
  /* ol */
  /* table */
  /* blockquote */
  /* hr */
  /* .align-
  --------------------- */
  /* .wp-caption
  --------------------- */
  /* iframe */
}
@media screen and (max-width: 960px) {
  .post-content {
    padding: 0 4vw;
  }
}
.post-content > *:first-child {
  margin-top: 0 !important;
}
.post-content img {
  height: auto;
  max-width: 100%;
}
.post-content p {
  font-size: 16px;
  line-height: 2;
  margin: 0 0 40px;
  padding: 0;
}
@media screen and (max-width: 560px) {
  .post-content p {
    margin: 0 0 20px;
  }
}
.post-content .small {
  font-size: 12px;
}
.post-content .large {
  font-size: 24px;
}
.post-content .x-large {
  font-size: 32px;
}
.post-content h2 {
  font-size: 30px;
  font-weight: 700;
  line-height: 1.5;
  margin: 80px 0 40px;
  padding-bottom: 10px;
  position: relative;
}
.post-content h2::after {
  content: "";
  background: -webkit-linear-gradient(right, #00F6FF 0%, #FF00DD 20%, #DFFF00 40%, #000000 60%, #000000 100%);
  background: -webkit-gradient(linear, left top, right top, color-stop(0%, #00F6FF), color-stop(20%, #FF00DD), color-stop(40%, #DFFF00), color-stop(60%, #000000), color-stop(100%, #000000));
  background: linear-gradient(to right, #00F6FF 0%, #FF00DD 20%, #DFFF00 40%, #000000 60%, #000000 100%);
  bottom: 0;
  height: 4px;
  left: 0;
  position: absolute;
  width: 100%;
}
.post-content h2 time {
  display: block;
  font-size: 1.6rem;
  margin-bottom: 8px;
}
.post-content h3 {
  font-size: 26px;
  font-weight: 700;
  line-height: 1.5;
  margin: 40px 0;
}
.post-content h4 {
  border-bottom: 1px solid #CCCCCC;
  font-size: 1.6rem;
  font-weight: 700;
  line-height: 1.5;
  margin: 40px 0 20px;
  padding: 0 0 10px;
}
.post-content em {
  font-style: italic;
  font-weight: inherit;
}
.post-content del {
  color: #FF0000;
}
.post-content ul {
  list-style-type: disc;
  margin: 0 0 40px;
  padding: 0 0 0 2em;
}
@media screen and (max-width: 560px) {
  .post-content ul {
    margin: 0 0 20px;
  }
}
.post-content ul li {
  font-size: 14px;
  margin: 0 0 10px;
}
.post-content ul li:last-child {
  margin: 0;
}
.post-content ul li > *:first-child {
  margin-top: 0 !important;
}
.post-content ul li > *:last-child {
  margin-bottom: 0 !important;
}
.post-content ol {
  list-style-type: decimal;
  margin: 0 0 40px;
  padding: 0 0 0 2em;
}
@media screen and (max-width: 560px) {
  .post-content ol {
    margin: 0 0 20px;
  }
}
.post-content ol li {
  font-size: 14px;
  margin: 0 0 10px;
}
.post-content ol li:last-child {
  margin: 0;
}
.post-content ol li > *:first-child {
  margin-top: 0 !important;
}
.post-content ol li > *:last-child {
  margin-bottom: 0 !important;
}
.post-content table {
  font-size: 16px;
  margin: 0 0 40px;
  width: 100%;
}
@media screen and (max-width: 560px) {
  .post-content table {
    margin: 0 0 20px;
    width: 600px;
  }
}
.post-content table th {
  background-color: #F5F5F5;
  border-bottom: 1px solid #ffffff;
  font-size: 100%;
  font-weight: 700;
  line-height: 2;
  padding: 20px 40px;
  vertical-align: top;
  white-space: nowrap;
  min-width: 220px;
}
.post-content table th > *:first-child {
  margin-top: 0 !important;
}
.post-content table th > *:last-child {
  margin-bottom: 0 !important;
}
.post-content table td {
  background-color: #FFFFFF;
  border-bottom: 1px solid #E8E8E8;
  font-size: 100%;
  line-height: 2;
  padding: 20px 40px;
  vertical-align: top;
}
.post-content table td > *:first-child {
  margin-top: 0 !important;
}
.post-content table td > *:last-child {
  margin-bottom: 0 !important;
}
.post-content table tr:last-child td {
  border-bottom: 0;
}
@media screen and (max-width: 560px) {
  .post-content .table {
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    width: 100%;
  }
  .post-content .table table th {
    padding: 10px;
  }
  .post-content .table table td {
    padding: 10px;
  }
}
.post-content blockquote {
  background-color: #F9F9F9;
  border: 2px dashed #D1D1D1;
  color: #000000;
  font-size: 14px;
  font-style: italic;
  margin: 0 0 40px;
  padding: 18px;
}
.post-content blockquote > *:first-child {
  margin-top: 0 !important;
}
.post-content blockquote > *:last-child {
  margin-bottom: 0 !important;
}
@media screen and (max-width: 560px) {
  .post-content blockquote {
    margin: 0 0 20px;
  }
}
.post-content blockquote a {
  color: #666666;
}
.post-content blockquote p {
  font-size: 14px;
  margin-bottom: 20px;
}
.post-content hr {
  background: #CCCCCC;
  border: 0;
  display: block;
  height: 1px;
  margin: 0 0 40px;
}
@media screen and (max-width: 560px) {
  .post-content hr {
    margin: 0 0 20px;
  }
}
.post-content .alignleft {
  float: left;
  height: auto;
  margin: 0 40px 40px 0;
  max-width: 100%;
}
@media screen and (max-width: 560px) {
  .post-content .alignleft {
    display: block;
    float: none;
    margin: 0 auto 20px;
  }
}
.post-content .alignright {
  float: right;
  height: auto;
  margin: 0 0 40px 40px;
  max-width: 100%;
}
@media screen and (max-width: 560px) {
  .post-content .alignright {
    display: block;
    float: none;
    margin: 0 auto 20px;
  }
}
.post-content .aligncenter {
  clear: both;
  display: block;
  height: auto;
  margin: 0 auto 40px;
  max-width: 100%;
}
@media screen and (max-width: 560px) {
  .post-content .aligncenter {
    margin: 0 auto 20px;
  }
}
.post-content .wp-caption {
  background: transparent;
  border: none;
  color: #000000;
  margin: 0 0 40px;
  max-width: 100%;
  padding: 0;
  text-align: left;
}
.post-content .wp-caption.alignleft {
  margin: 0 40px 40px 0;
}
.post-content .wp-caption.alignright {
  margin: 0 0 40px 40px;
}
.post-content .wp-caption.aligncenter {
  margin: 0 auto 40px;
}
@media screen and (max-width: 560px) {
  .post-content .wp-caption {
    margin: 0 0 20px;
  }
  .post-content .wp-caption.alignleft {
    margin: 0 20px 20px 0;
  }
  .post-content .wp-caption.alignright {
    margin: 0 0 20px 20px;
  }
  .post-content .wp-caption.aligncenter {
    margin: 0 auto 20px;
  }
}
.post-content .wp-caption-dt {
  margin: 0;
  padding: 0;
}
.post-content .wp-caption .wp-caption-text,
.post-content .wp-caption-dd {
  font-size: 12px;
  font-style: normal;
  line-height: 1.5;
  margin: 0;
  padding: 10px 0 0;
  text-align: center;
}
.post-content .mce-object-iframe {
  border: 0;
  width: 100%;
}

/* COMMON
--------------------- */
/* .form */
.post-content table.form {
  margin-bottom: 40px;
  text-align: left;
  width: 100%;
}
@media screen and (max-width: 960px) {
  .post-content table.form {
    margin-bottom: 20px;
  }
}
.post-content table.form th {
  background: 0;
  font-size: 16px;
  font-weight: 700;
  padding: 10px 0;
  vertical-align: top;
  min-width: auto;
}
@media screen and (max-width: 960px) {
  .post-content table.form th {
    padding: 5px 0;
  }
}
.post-content table.form th label {
  display: block;
  padding: 4px 0;
}
@media screen and (max-width: 960px) {
  .post-content table.form th label {
    padding: 0;
  }
}
.post-content table.form td {
  border-bottom: 0;
  font-size: 16px;
  padding: 10px 0;
  position: relative;
  vertical-align: top;
}
@media screen and (max-width: 960px) {
  .post-content table.form td {
    padding: 5px 0;
  }
}
.post-content table.form input {
  width: 100%;
}
.post-content table.form input,
.post-content table.form select {
  background: #FBFBFB;
  border: 1px solid #ccc;
  height: 40px;
  padding: 0 10px;
}
@media screen and (max-width: 960px) {
  .post-content table.form input,
  .post-content table.form select {
    height: 32px;
  }
}
.post-content table.form input.w110,
.post-content table.form select.w110 {
  width: 110px;
}
.post-content table.form input.w150,
.post-content table.form select.w150 {
  width: 150px;
}
.post-content table.form textarea {
  background: #FBFBFB;
  border: 1px solid #ccc;
  height: 200px;
  padding: 10px;
  width: 100%;
}
.post-content table.form .form-error {
  background: #ff0000;
  color: #fff;
  font-size: 12px;
  left: -10px;
  line-height: 1;
  margin-top: 5px;
  padding: 5px;
  position: absolute;
  top: -10px;
}
.post-content table.form .form-error::after {
  border-left: 3px solid transparent;
  border-right: 3px solid transparent;
  border-top: 6px solid #ff0000;
  bottom: -6px;
  content: "";
  left: 20px;
  position: absolute;
}

.form__btn-change-date {
  background: #000;
  color: #fff;
  padding: 0 10px;
}
.form__btn-change-date:hover {
  opacity: 0.5;
}

/* .buttons */
.post-content .buttons {
  position: relative;
  text-align: center;
}
.post-content .buttons button {
  background: #000;
  color: #ffffff;
  display: inline-block;
  font-size: 20px;
  font-weight: 700;
  line-height: 1;
  padding: 20px 0;
  text-align: center;
  transition: 0.3s ease-in-out;
  max-width: 240px;
  width: 100%;
}
.post-content .buttons button.buttons__send {
  background: #DC3131;
}
.post-content .buttons button.buttons__cancel {
  background: 0;
  color: #DC3131;
  position: absolute;
  right: 0;
  top: 0;
  width: auto;
}
@media screen and (max-width: 960px) {
  .post-content .buttons button.buttons__cancel {
    display: block;
    font-size: 16px;
    margin: 20px 0 0 auto;
    position: relative;
    width: 80px;
  }
}
.post-content .buttons button:hover {
  opacity: 0.5;
}
.post-content .buttons ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
.post-content .buttons li {
  float: left;
  margin: 0 4% 0 0;
  padding: 0;
  width: 48%;
}
.post-content .buttons li:last-child {
  margin-right: 0;
}
.post-content .buttons li button {
  cursor: pointer;
  width: 100%;
}

/* TOP
--------------------- */
.top {
  background: url("../img/top/visual_mask.svg") 0% 0% repeat, url("../img/top/visual_bg_01.jpg") 50% 50% no-repeat;
  background-size: auto, cover;
  height: 100vh;
  width: 100%;
}
.top > div {
  background: rgba(0, 0, 0, 0.64);
}
.top > div > div {
  padding-bottom: 190px;
}
@media screen and (max-width: 960px) {
  .top > div > div {
    padding-bottom: 0;
  }
}

/* .top__logo */
.top__logo {
  margin-bottom: 60px;
}
@media screen and (max-width: 960px) {
  .top__logo {
    margin-bottom: 0;
  }
  .top__logo img {
    width: 40vw;
  }
}
@media screen and (max-width: 560px) {
  .top__logo img {
    width: 60vw;
  }
}

/* .top__nav */
@media screen and (max-width: 960px) {
  .top__nav {
    background: #000000;
    display: none;
    height: 100vh;
    left: 0;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 1;
  }
}
.top__nav li {
  display: inline-block;
  margin-right: 40px;
}
.top__nav li:first-child {
  display: none;
}
.top__nav li.last {
  margin-right: 0;
}
@media screen and (max-width: 960px) {
  .top__nav li {
    display: block;
    margin: 0 0 5vh;
  }
  .top__nav li:first-child {
    display: block;
  }
  .top__nav li:last-child {
    margin-bottom: 0;
  }
}
.top__nav li a {
  color: #ffffff;
  font-size: 2rem;
  font-weight: 700;
  line-height: 1;
  text-decoration: none;
}
@media screen and (max-width: 960px) {
  .top__nav li a {
    font-size: 5vw;
  }
}

/* .top__nav__logo */
.top__nav__logo {
  display: none;
}
@media screen and (max-width: 960px) {
  .top__nav__logo {
    display: block;
    left: 2vw;
    position: absolute;
    top: 2vw;
    z-index: 1;
  }
  .top__nav__logo img {
    height: 8vw;
  }
}

/* .top__news */
.top__news {
  padding: 0 20px;
}
.top__news > div {
  border: 1px solid #fff;
  height: 30px;
  margin: 60px auto 0;
  max-width: 600px;
}

.top__news__hd {
  background: #fff;
  color: #000;
  float: left;
  font-size: 1.6rem;
  height: 100%;
  line-height: 1;
  padding-top: 6px;
  width: 100px;
}

.top__news__content {
  color: #fff;
  float: left;
  font-size: 1.6rem;
  height: 100%;
  line-height: 1;
  text-align: left;
  width: -webkit-calc(100% - 100px);
  width: calc(100% - 100px);
}
.top__news__content > p {
  display: none;
  margin: 0;
  padding: 6px 10px 0;
}

/* .top__gallery */
.top__gallery {
  background: rgba(0, 0, 0, 0.5);
  bottom: 0;
  height: 190px;
  left: 0;
  margin: 0 auto;
  padding: 20px 60px;
  position: absolute;
  width: 100%;
}
@media screen and (max-width: 960px) {
  .top__gallery {
    display: none;
  }
}
.top__gallery .visual-animate__layer ul li {
  background: #000000;
  cursor: pointer;
  margin-right: 20px;
}
@media screen and (max-width: 560px) {
  .top__gallery .visual-animate__layer ul {
    height: 60vw;
  }
}
.top__gallery .visual-animate__layer__inner {
  overflow: visible;
  max-width: 200px;
}
@media screen and (max-width: 560px) {
  .top__gallery .visual-animate__layer__inner {
    max-width: 100%;
  }
}
.top__gallery .visual-animate__btn-prev {
  left: -60px;
}
@media screen and (max-width: 560px) {
  .top__gallery .visual-animate__btn-prev {
    left: -10vw;
  }
}
.top__gallery .visual-animate__btn-next {
  right: -60px;
}
@media screen and (max-width: 560px) {
  .top__gallery .visual-animate__btn-next {
    right: -10vw;
  }
}
.top__gallery .visual-animate__btn-prev img,
.top__gallery .visual-animate__btn-next img {
  width: 30%;
}

.top__gallery__large {
  display: none;
}

/* GUIDE
--------------------- */
/* .guide__slider */
.guide__slider {
  height: 460px;
  margin-bottom: 40px;
}
@media screen and (max-width: 960px) {
  .guide__slider {
    height: 70.375vw;
  }
}
.guide__slider > div {
  height: 460px;
  position: absolute;
  left: 0;
  width: 100%;
}
@media screen and (max-width: 960px) {
  .guide__slider > div {
    height: 70.375vw;
    position: relative;
  }
}
.guide__slider .visual-animate__layer ul {
  box-sizing: content-box;
  list-style: none;
  padding: 0 0 10vw;
}
.guide__slider .visual-animate__layer ul li {
  margin: 0 80px 0 0;
}
@media screen and (max-width: 560px) {
  .guide__slider .visual-animate__layer ul li {
    margin-right: 0;
  }
}
.guide__slider .visual-animate__layer p {
  bottom: -2.21em;
  font-size: 1.4rem;
  line-height: 1.5;
  margin: 0;
  position: absolute;
  text-align: center;
  width: 100%;
}
.guide__slider .visual-animate__layer__inner {
  height: 420px;
  max-width: 640px;
  overflow: visible;
}
@media screen and (max-width: 960px) {
  .guide__slider .visual-animate__layer__inner {
    height: 60.375vw;
  }
}
@media screen and (max-width: 560px) {
  .guide__slider .visual-animate__layer__inner {
    max-width: 100%;
  }
}
.guide__slider .visual-animate__btn-prev {
  height: 420px;
  left: 50%;
  margin-left: -390px;
  text-align: center;
}
@media screen and (max-width: 960px) {
  .guide__slider .visual-animate__btn-prev {
    height: 60.375vw;
  }
}
@media screen and (max-width: 560px) {
  .guide__slider .visual-animate__btn-prev {
    left: -4vw;
    margin-left: 0;
    width: 10vw;
  }
  .guide__slider .visual-animate__btn-prev span {
    background: rgba(0, 0, 0, 0.8);
    padding: 4vw 0;
  }
}
.guide__slider .visual-animate__btn-next {
  height: 420px;
  right: 50%;
  margin-right: -390px;
  text-align: center;
}
@media screen and (max-width: 960px) {
  .guide__slider .visual-animate__btn-next {
    height: 60.375vw;
  }
}
@media screen and (max-width: 560px) {
  .guide__slider .visual-animate__btn-next {
    margin-right: 0;
    right: -4vw;
    width: 10vw;
  }
  .guide__slider .visual-animate__btn-next span {
    background: rgba(0, 0, 0, 0.8);
    padding: 4vw 0;
  }
}
.guide__slider .visual-animate__btn-prev img,
.guide__slider .visual-animate__btn-next img {
  width: 50%;
}
@media screen and (max-width: 560px) {
  .guide__slider .visual-animate__btn-prev img,
  .guide__slider .visual-animate__btn-next img {
    width: 20%;
  }
}
.guide__slider .visual-animate__overlay {
  background: rgba(255, 255, 255, 0.8);
  width: -webkit-calc((100% - 640px) / 2 - 80px);
  width: calc((100% - 640px) / 2 - 80px);
}

/* reservation
--------------------- */
.reservation__icons {
  padding: 0 !important;
  text-align: left;
}
.reservation__icons li {
  display: inline-block;
  font-size: 18px !important;
  font-weight: 700;
  list-style: none;
  margin-right: 40px !important;
}
@media screen and (max-width: 560px) {
  .reservation__icons li {
    display: block;
    margin-right: 0 !important;
  }
}
.reservation__icons img {
  margin-right: 10px;
}

.calendar {
  position: relative;
}
.calendar table {
  width: 600px;
}
.calendar table thead th {
  background: #F9F9F9;
  border-bottom: 0;
  line-height: 1;
  min-width: inherit;
  padding: 20px;
  position: relative;
  text-align: center;
  vertical-align: middle;
}
.calendar table thead th:last-child {
  border-right: 1px solid #F9F9F9;
}
@media screen and (max-width: 560px) {
  .calendar table thead th {
    padding: 20px 0 !important;
  }
}
.calendar table thead th span {
  display: inline-block;
  width: 33%;
}
.calendar table thead th a,
.calendar table thead th span {
  color: #000000;
  cursor: pointer;
  display: inline-block;
  text-decoration: none;
  width: 33%;
}
.calendar table thead th a.calendar__prev,
.calendar table thead th span.calendar__prev {
  text-align: left;
}
.calendar table thead th a.calendar__next,
.calendar table thead th span.calendar__next {
  text-align: right;
}
.calendar table thead tr:nth-child(1) th {
  font-size: 30px;
  width: 100%;
}
@media screen and (max-width: 560px) {
  .calendar table thead tr:nth-child(1) th {
    font-size: 20px;
  }
}
.calendar table thead tr:nth-child(1) th::after {
  background: #DBDBDB;
  bottom: 0;
  content: "";
  height: 1px;
  left: 20px;
  position: absolute;
  width: -webkit-calc(100% - 40px);
  width: calc(100% - 40px);
}
.calendar table thead tr:nth-child(2) th {
  font-size: 20px;
  width: 14.28%;
}
@media screen and (max-width: 560px) {
  .calendar table thead tr:nth-child(2) th {
    font-size: 18px;
  }
}
.calendar table thead tr:nth-child(2) th::after {
  background: #DBDBDB;
  content: "";
  height: -webkit-calc(100% - 40px);
  height: calc(100% - 40px);
  position: absolute;
  right: 0;
  top: 20px;
  width: 1px;
}
.calendar table thead tr:nth-child(2) th:last-child::after {
  display: none;
}
.calendar table td {
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: 24px 24px;
  border-bottom: 1px solid #F1F1F1 !important;
  border-right: 1px solid #F1F1F1;
  height: 85px;
  line-height: 1;
  min-width: inherit;
  text-align: center;
  padding: 0;
  position: relative;
  vertical-align: middle;
  width: 14.28%;
}
@media screen and (max-width: 560px) {
  .calendar table td {
    background-size: auto 40%;
    height: 60px;
  }
}
.calendar table td > time {
  font-size: 20px;
  font-weight: 700;
  left: 10px;
  position: absolute;
  top: 10px;
}
@media screen and (max-width: 560px) {
  .calendar table td > time {
    font-size: 18px;
    left: 5px;
    top: 5px;
  }
}
.calendar table td:first-child {
  border-left: 1px solid #F1F1F1 !important;
}
.calendar table td.off > time {
  color: #C8C8C8;
}
.calendar table td.closed {
  background-image: url("../img/reservation/reservation_ico_closed.svg");
}
.calendar table td.ok {
  background-image: url("../img/reservation/reservation_ico_ok.svg");
  cursor: pointer;
}
.calendar table td.no {
  background-image: url("../img/reservation/reservation_ico_no.svg");
}
.calendar.loading::after {
  background: rgba(255, 255, 255, 0.8) url("../img/loading_blk.svg") 50% 50% no-repeat;
  background-size: 80px 80px;
  content: "";
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.calendar__user {
  color: #0090FF;
  cursor: pointer;
  font-size: 14px;
  line-height: 1.5;
  text-decoration: underline;
}

.overlay--reservation {
  display: none;
  height: 100%;
  padding: 20px 0;
}
@media screen and (max-width: 960px) {
  .overlay--reservation {
    padding: 0;
  }
}
@media screen and (max-width: 960px) {
  .overlay--reservation > div {
    display: block;
  }
  .overlay--reservation > div > div {
    display: block;
    overflow: auto;
  }
}
.overlay--reservation table.form {
  margin-bottom: 20px;
}

.reservation-overlay-content {
  display: none;
  margin: 0 auto;
  background: #ffffff;
  max-height: calc(100vh - 40px);
  max-width: 640px;
  overflow: auto;
  padding: 40px;
  position: relative;
}
@media screen and (max-width: 960px) {
  .reservation-overlay-content {
    max-height: 100vh;
    max-width: 100%;
    padding: 4vw;
  }
}
.reservation-overlay-content .overlay__btn-close {
  background-image: url("../img/overlay_ico_close_blk.svg");
}

.reservation-form__agree {
  font-size: 16px;
  margin-bottom: 40px;
  position: relative;
  text-align: center;
}
@media screen and (max-width: 960px) {
  .reservation-form__agree {
    margin-bottom: 20px;
  }
}
.reservation-form__agree input {
  margin-right: 10px;
}
.reservation-form__agree .form-error {
  background: #ff0000;
  color: #fff;
  font-size: 12px;
  left: 30%;
  line-height: 1;
  margin-top: 5px;
  padding: 5px;
  position: absolute;
  top: -30px;
}
.reservation-form__agree .form-error::after {
  border-left: 3px solid transparent;
  border-right: 3px solid transparent;
  border-top: 6px solid #ff0000;
  bottom: -6px;
  content: "";
  left: 20px;
  position: absolute;
}

.reservation-form__sendmail {
  font-size: 16px;
  margin-bottom: 40px;
  position: relative;
  text-align: center;
}
@media screen and (max-width: 960px) {
  .reservation-form__sendmail {
    margin-bottom: 20px;
  }
}
.reservation-form__sendmail input {
  margin-right: 10px;
}

.reservation-overlay-content__message p {
  font-size: 16px;
  line-height: 2;
  margin: 40px 0 0;
  text-align: center;
}
.reservation-overlay-content__message .buttons {
  margin-top: 40px;
}

.reservation-overlay-content__message__hd {
  font-size: 20px;
  font-weight: 700;
  text-align: center;
}

.reservation-overlay-content__message__txt1 {
  color: #ff0000;
}

.reservation-calendar {
  max-width: 680px;
}
.reservation-calendar .buttons__no-change-date {
  max-width: 440px !important;
}
.reservation-calendar .calendar td,
.reservation-calendar .calendar__user {
  cursor: default !important;
}
.reservation-calendar .calendar td.ok {
  cursor: pointer !important;
}

/* reservation-admin
--------------------- */
.reservation-admin .calendar td.no {
  background: 0;
}
.reservation-admin .calendar td.closed {
  cursor: pointer;
}

/* cancel
--------------------- */
.cancel .post-content .cancel-txt1 {
  text-align: center;
}
.cancel .post-content .cancel-error {
  color: #ff0000;
  margin-bottom: 20px;
  text-align: center;
}
.cancel .post-content .cancel-form {
  text-align: center;
}
.cancel .post-content .cancel-form span {
  font-weight: 700;
}
.cancel .post-content .cancel-form input {
  border: 1px solid #ccc;
  height: 50px;
  margin-left: 40px;
  padding: 0 10px;
  width: 400px;
}
.cancel .post-content .cancel-form .buttons {
  margin-top: 40px;
}
.cancel .post-content .cancel-hd {
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 40px;
  text-align: center;
}

/* access
--------------------- */
.access-gmap {
  border: 1px solid #ccc;
  display: block;
  height: 400px;
  margin-bottom: 40px;
  width: 100%;
}

.access-sec {
  margin: 0 auto 80px;
}

.access-sec__hd {
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 20px !important;
}

/* contact
--------------------- */
.contact .post-content {
  max-width: 600px;
}
.contact .post-content table.form th {
  min-width: 220px;
}
.contact .post-content .contact-agree {
  font-size: 16px;
  margin-bottom: 40px;
  text-align: center;
}
.contact .post-content .contact-agree input {
  margin-right: 10px;
}
.contact .post-content .mw_wp_form_confirm .contact-agree {
  display: none;
}
.contact .post-content .buttons__back {
  margin-right: 20px;
}
.contact .post-content .contact-complete-hd {
  font-size: 20px;
  font-weight: 700;
  text-align: center;
}
.contact .post-content .contact-complete-msg {
  text-align: center;
}
.contact .post-content .form th {
  display: block;
  padding: 0;
}
.contact .post-content .form td {
  display: block;
  margin-bottom: 20px;
  padding: 0;
}

/* mypage
--------------------- */
.mypage .post-content table th {
  vertical-align: middle;
  width: 150px;
}
.mypage .post-content .mypage-cols__detail {
  float: left;
  width: -webkit-calc(100% - 120px);
  width: calc(100% - 120px);
}
.mypage .post-content .mypage-cols__detail dl {
  line-height: 1.5;
  margin-bottom: 10px;
}
.mypage .post-content .mypage-cols__detail dt {
  font-weight: 700;
}
.mypage .post-content .mypage-cols__detail dd {
  margin: -1.5em 0 0 10em;
}
.mypage .post-content .mypage-cols__control {
  float: right;
  width: 100px;
}
.mypage .post-content .mypage-cols__control ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
.mypage .post-content .mypage-cols__control li {
  border: 1px solid #C5C5C5;
  cursor: pointer;
  font-size: 14px;
  line-height: 1;
  margin: 0 0 20px;
  padding: 10px 0;
  text-align: center;
}
.mypage .post-content .mypage-cols__control li:last-child {
  margin-bottom: 0;
}
.mypage .post-content .mypage-cols__control li:hover {
  opacity: 0.5;
}
.mypage .post-content .mypage-accordion {
  display: none;
}
.mypage .post-content .mypage-accordion-btn {
  border: 1px solid #D1D1D1;
  cursor: pointer;
  font-size: 14px;
  line-height: 1.5;
  text-align: center;
}
.mypage .post-content .mypage-accordion-btn:hover {
  opacity: 0.5;
}
.mypage .post-content .mypage-passwd-txt1 {
  text-align: center;
}
.mypage .post-content .mypage-passwd-form {
  text-align: center;
}
.mypage .post-content .mypage-passwd-form span {
  font-weight: 700;
}
.mypage .post-content .mypage-passwd-form input {
  border: 1px solid #ccc;
  height: 50px;
  margin-left: 40px;
  padding: 0 10px;
  width: 200px;
}
.mypage .post-content .mypage-passwd-form .buttons {
  margin-top: 40px;
}
.mypage .post-content .mypage-passwd-hd {
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 40px;
  text-align: center;
}
.mypage .post-content .mypage-passwd-error {
  color: #ff0000;
  margin-bottom: 20px;
  text-align: center;
}
.mypage .post-content .mypage-email {
  display: none;
}
.mypage .post-content .mypage-ID {
  display: none;
}
.mypage .post-content .mypage-contents.loading::after {
  background: rgba(255, 255, 255, 0.8) url("../img/loading_blk.svg") 50% 50% no-repeat;
  background-size: 80px 80px;
  content: "";
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

/* .page-top
--------------------- */
.page-top {
  background: #000 url(../img/page_top_ico.svg) 50% 50% no-repeat;
  border-radius: 30px;
  cursor: pointer;
  height: 60px;
  position: absolute;
  right: 20px;
  top: -80px;
  width: 60px;
  z-index: 1;
}
@media screen and (max-width: 560px) {
  .page-top {
    right: 4vw;
    top: -80px;
  }
}
.page-top.fixed {
  bottom: 20px;
  position: fixed;
  right: 20px;
  top: inherit;
}
.page-top:hover {
  opacity: 1 !important;
}

/* #footer
--------------------- */
#footer {
  background: #000;
  padding: 40px 20px;
  position: relative;
  width: 100%;
}
@media screen and (max-width: 960px) {
  #footer {
    padding: 40px 4vw 20px;
  }
}

.footer__in {
  margin: 0 auto;
  max-width: 1160px;
  position: relative;
}

/* logo */
@media screen and (max-width: 960px) {
  .footer__logo {
    margin-bottom: 40px;
    text-align: center;
  }
}
.footer__logo img {
  height: 152px;
}

/* nav */
.footer__nav {
  position: absolute;
  right: 0;
  top: 0;
}
@media screen and (max-width: 960px) {
  .footer__nav {
    display: none;
  }
}
.footer__nav li {
  display: inline;
  font-size: 1.4rem;
  font-weight: 700;
  margin-right: 20px;
}
.footer__nav li:last-child {
  margin-right: 0;
}
.footer__nav a {
  color: #ffffff;
  text-decoration: none;
}
.footer__nav a:hover {
  text-decoration: underline;
}

/* .footer__copyright */
.footer__copyright {
  bottom: 0;
  color: #ffffff;
  font-size: 1.4rem;
  position: absolute;
  right: 0;
}
@media screen and (max-width: 960px) {
  .footer__copyright {
    font-size: 1rem;
    position: relative;
    text-align: center;
  }
}

/* news
--------------------- */
.news-archive {
  margin: 0 auto 136px;
  max-width: 1200px;
  padding-left: 20px;
  padding-right: 20px;
  width: 100%;
}
@media screen and (max-width: 960px) {
  .news-archive {
    padding-left: 4vw;
    padding-right: 4vw;
  }
}
.news-archive dl {
  border-bottom: 1px solid #ccc;
  border-top: 1px solid #ccc;
  display: flex;
  font-size: 1.6rem;
  margin-bottom: -1px;
  padding: 32px 16px;
}
@media screen and (max-width: 560px) {
  .news-archive dl {
    flex-direction: column;
    padding: 4.2666666667vw 2.1333333333vw;
  }
}
.news-archive dt {
  margin-right: 48px;
  flex-shrink: 0;
}
@media screen and (max-width: 560px) {
  .news-archive dt {
    margin-right: 0;
  }
}
.news-archive dd {
  width: 100%;
}

/* Pagination
--------------------- */
.cmn-pagination {
  margin-top: 48px;
}
.cmn-pagination ul {
  align-items: center;
  display: flex;
  justify-content: center;
}
.cmn-pagination__item, .cmn-pagination__item--first, .cmn-pagination__item--last, .cmn-pagination__item--prev, .cmn-pagination__item--next, .cmn-pagination__item--current, .cmn-pagination__item--ellipsis {
  font-size: 1.6rem;
  height: 40px;
  margin: 0 5px;
  text-align: center;
  width: 40px;
}
.cmn-pagination__item a, .cmn-pagination__item--first a, .cmn-pagination__item--last a, .cmn-pagination__item--prev a, .cmn-pagination__item--next a, .cmn-pagination__item--current a, .cmn-pagination__item--ellipsis a,
.cmn-pagination__item span,
.cmn-pagination__item--first span,
.cmn-pagination__item--last span,
.cmn-pagination__item--prev span,
.cmn-pagination__item--next span,
.cmn-pagination__item--current span,
.cmn-pagination__item--ellipsis span {
  background: #000;
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.5);
  color: #fff;
  display: block;
  line-height: 40px;
  height: 100%;
  text-decoration: none;
  width: 100%;
}
@media screen and (max-width: 560px) {
  .cmn-pagination__item a, .cmn-pagination__item--first a, .cmn-pagination__item--last a, .cmn-pagination__item--prev a, .cmn-pagination__item--next a, .cmn-pagination__item--current a, .cmn-pagination__item--ellipsis a,
  .cmn-pagination__item span,
  .cmn-pagination__item--first span,
  .cmn-pagination__item--last span,
  .cmn-pagination__item--prev span,
  .cmn-pagination__item--next span,
  .cmn-pagination__item--current span,
  .cmn-pagination__item--ellipsis span {
    line-height: 10.6666666667vw;
  }
}
.cmn-pagination__item a:hover, .cmn-pagination__item--first a:hover, .cmn-pagination__item--last a:hover, .cmn-pagination__item--prev a:hover, .cmn-pagination__item--next a:hover, .cmn-pagination__item--current a:hover, .cmn-pagination__item--ellipsis a:hover {
  background: #333;
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.5);
  color: #FFF;
}
.cmn-pagination__item--first span, .cmn-pagination__item--last span, .cmn-pagination__item--prev span, .cmn-pagination__item--next span {
  opacity: 0.25;
}
.cmn-pagination__item--first {
  display: none;
  margin: 0 5px 0 0;
}
.cmn-pagination__item--last {
  display: none;
  margin: 0 0 0 5px;
}
.cmn-pagination__item--prev {
  margin: 0 5px 0 0;
}
.cmn-pagination__item--next {
  margin: 0 0 0 5px;
}
.cmn-pagination__item--current span.cmn-pagination__sp-txt {
  display: none;
}
@media screen and (max-width: 560px) {
  .cmn-pagination__item--current span.cmn-pagination__sp-txt {
    background: 0;
    box-shadow: none;
    color: #000;
    display: inline-block;
  }
}
.cmn-pagination__item--current span.cmn-pagination__pc-txt {
  background: #fff;
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.5);
  color: #000;
  line-height: 40px;
}
@media screen and (max-width: 560px) {
  .cmn-pagination__item--current span.cmn-pagination__pc-txt {
    display: none;
  }
}
.cmn-pagination__item--ellipsis {
  line-height: 40px;
}
@media screen and (max-width: 560px) {
  .cmn-pagination__item, .cmn-pagination__item--first, .cmn-pagination__item--last, .cmn-pagination__item--prev, .cmn-pagination__item--next, .cmn-pagination__item--current, .cmn-pagination__item--ellipsis {
    display: none;
    font-size: 4.2666666667vw;
    height: 10.6666666667vw;
    line-height: 10.6666666667vw;
    margin: 0;
    width: 10.6666666667vw;
  }
  .cmn-pagination__item--first, .cmn-pagination__item--last, .cmn-pagination__item--prev, .cmn-pagination__item--next {
    display: block;
    flex-shrink: 0;
  }
  .cmn-pagination__item--first {
    margin-right: 2.1333333333vw;
  }
  .cmn-pagination__item--last {
    margin-left: 2.1333333333vw;
  }
  .cmn-pagination__item--current {
    background: 0;
    box-shadow: none;
    display: block;
    line-height: 10.6666666667vw;
    width: 100%;
  }
}
/* top-news
--------------------- */
@keyframes news-top {
  0% {
    transform: translate(0, 100%);
  }
  100% {
    transform: translate(0, 0);
  }
}
.news-top {
  background: #fff;
  bottom: 0;
  padding: 40px 40px 64px;
  position: fixed;
  right: 0;
  transform: translate(0, 100%);
  width: 640px;
  z-index: 9;
  animation: 0.5s 1s forwards news-top;
}
@media screen and (max-width: 960px) {
  .news-top {
    padding: 4.1666666667vw 4.1666666667vw 6.6666666667vw;
    width: 100%;
  }
}
@media screen and (max-width: 560px) {
  .news-top {
    padding: 6.4vw 4.2666666667vw 10.6666666667vw;
  }
}
.news-top__close {
  cursor: pointer;
  font-size: 1.4rem;
  padding-right: 24px;
  position: absolute;
  right: 16px;
  top: 16px;
  z-index: 1;
}
@media screen and (max-width: 960px) {
  .news-top__close {
    font-size: 1.4583333333vw;
    padding-right: 2.5vw;
    right: 1.6666666667vw;
    top: 1.6666666667vw;
  }
}
@media screen and (max-width: 560px) {
  .news-top__close {
    font-size: 2.9333333333vw;
    padding-right: 4.2666666667vw;
    right: 2.1333333333vw;
    top: 2.1333333333vw;
  }
}
.news-top__close::after {
  background: url("../img/overlay_ico_close_blk.svg") 50% 50% no-repeat;
  background-size: 100% 100%;
  content: "";
  display: block;
  height: 16px;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translate(0, -50%);
  width: 16px;
}
@media screen and (max-width: 960px) {
  .news-top__close::after {
    height: 1.6666666667vw;
    width: 1.6666666667vw;
  }
}
@media screen and (max-width: 560px) {
  .news-top__close::after {
    height: 3.2vw;
    width: 3.2vw;
  }
}
.news-top time {
  display: block;
  font-size: 1.4rem;
  line-height: 1;
  margin-bottom: 8px;
  text-align: center;
}
@media screen and (max-width: 960px) {
  .news-top time {
    font-size: 1.6770833333vw;
    margin-bottom: 0.8333333333vw;
  }
}
@media screen and (max-width: 560px) {
  .news-top time {
    font-size: 2.9333333333vw;
    margin-bottom: 2.1333333333vw;
  }
}
.news-top__title {
  font-size: 2rem;
  font-weight: 700;
  line-height: 1.2;
  margin-bottom: 32px;
  text-align: center;
}
@media screen and (max-width: 960px) {
  .news-top__title {
    font-size: 2.3958333333vw;
    margin-bottom: 3.3333333333vw;
  }
}
@media screen and (max-width: 560px) {
  .news-top__title {
    font-size: 3.7333333333vw;
    margin-bottom: 4.2666666667vw;
  }
}
.news-top__title a {
  color: #000;
  text-decoration: none;
}
.news-top__contents {
  display: flex;
  max-height: 248px;
  position: relative;
}
.news-top__contents figure {
  flex-shrink: 0;
  margin-right: 24px;
}
@media screen and (max-width: 960px) {
  .news-top__contents figure {
    margin-right: 2.5vw;
  }
}
@media screen and (max-width: 560px) {
  .news-top__contents figure {
    margin-right: 4.2666666667vw;
  }
}
.news-top__contents figure img {
  max-width: 248px;
}
@media screen and (max-width: 960px) {
  .news-top__contents figure img {
    max-width: 25.8333333333vw;
  }
}
@media screen and (max-width: 560px) {
  .news-top__contents figure img {
    max-width: 26.6666666667vw;
  }
}
.news-top__contents__content {
  overflow: auto;
  width: 100%;
}
.news-top__contents__content p {
  font-size: 1.4rem;
  line-height: 1.5;
}
@media screen and (max-width: 960px) {
  .news-top__contents__content p {
    font-size: 1.6770833333vw;
  }
}
@media screen and (max-width: 560px) {
  .news-top__contents__content p {
    font-size: 2.9333333333vw;
  }
}
.news-top__contents__content p + p {
  margin-top: 1em;
}
.news-top__contents__content .small {
  font-size: 1.2rem;
}
.news-top__contents__content .large {
  font-size: 2.4rem;
}
.news-top__contents__content .x-large {
  font-size: 3.2rem;
}
.news-top__contents__more {
  bottom: -1.5em;
  font-size: 1.4rem;
  line-height: 1;
  position: absolute;
  right: 0;
}
@media screen and (max-width: 960px) {
  .news-top__contents__more {
    font-size: 1.4583333333vw;
  }
}
@media screen and (max-width: 560px) {
  .news-top__contents__more {
    font-size: 2.9333333333vw;
  }
}
.news-top__contents__more a {
  color: #000;
  text-decoration: none;
}
.news-top__contact {
  bottom: 0;
  position: absolute;
  right: 0;
}
.news-top__contact a {
  background: #000;
  color: #fff;
  display: block;
  font-size: 2rem;
  font-weight: 700;
  line-height: 1;
  padding: 20px 24px 20px 60px;
  position: relative;
  text-decoration: none;
  width: 100%;
}
@media screen and (max-width: 960px) {
  .news-top__contact a {
    font-size: 2.0833333333vw;
    padding: 2.0833333333vw 2.5vw 2.0833333333vw 6.25vw;
  }
}
@media screen and (max-width: 560px) {
  .news-top__contact a {
    font-size: 3.7333333333vw;
    padding: 3.4666666667vw 4.2666666667vw 3.4666666667vw 10.6666666667vw;
  }
}
.news-top__contact a::after {
  background: url("../img/ico_email_wht.svg") 50% 50% no-repeat;
  background-size: 20px 15px;
  content: "";
  display: block;
  height: 15px;
  left: 24px;
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  width: 20px;
}
@media screen and (max-width: 960px) {
  .news-top__contact a::after {
    background-size: 2.0833333333vw 1.5625vw;
    height: 1.5625vw;
    left: 2.5vw;
    width: 2.0833333333vw;
  }
}
@media screen and (max-width: 560px) {
  .news-top__contact a::after {
    background-size: 4.2666666667vw 3.2vw;
    height: 3.2vw;
    left: 4.2666666667vw;
    width: 4.2666666667vw;
  }
}